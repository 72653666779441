import { AuthState } from "@okta/okta-auth-js";
import * as classNames from "classnames";
import * as React from "react";
import { numberOfEmptyRows, responseListDefaultSort } from "../../AppConstants";
import appHistory from "../../AppHistory";
import i18n from "../../i18n";
import { EmptyListModal } from "../EmptyListModal/EmptyListModal";
import Pagination from "../Pagination/Index";
import { SkeletonTableRow } from "../SkeletonTableRow";
import { ResponseListRow } from "./ResponseListRow";
import "./Responses.scss";

export interface IResponseListProps {
  list: Api.IPagedResult<Api.IResponseListViewModel>;
  baseRoute: string;
  isDraftList: boolean;
  authState: AuthState | null;
  handlePageSizeChange: (
    pageNumber: number,
    itemPerPage: number,
    sortOrder: string,
    auth: AuthState | null,
    searchKeyword?: string
  ) => any;
}

class Responses extends React.Component<IResponseListProps> {
  constructor(props: IResponseListProps) {
    super(props);

    this.sortOrderChanged = this.sortOrderChanged.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
  }

  public render() {
    const clsName =
      this.props.list.sortOrder.indexOf("-") > -1
        ? "fa fa-sort-amount-down"
        : "fa fa-sort-amount-up";
    const isSkeleton: boolean =
      this.props.list.results && this.props.list.rowCount === 0;
    return (
      <div className="drafts-responses">
        <table
          className={classNames(
            "table",
            { "table-hover": !isSkeleton },
            "table-responsive"
          )}
        >
          <thead>
            <tr>
              <th className="referenceId" scope="col">
                {i18n.t("responseList.titleIdHeading")}
              </th>
              <th className="title" scope="col">
                {i18n.t("responseList.titleColHeading")}
              </th>
              <th className="category" scope="col">
                {i18n.t("responseList.categoryHeading")}
              </th>
              <th className="date" scope="col">
                <span className="date-label">
                  {i18n.t("responseList.dateColHeading")}
                </span>
                <i
                  role="sort-icon"
                  className={clsName}
                  onClick={this.sortOrderChanged}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.list.results &&
              this.props.list.results.map((item) =>
                renderListItem(item, this.props.isDraftList)
              )}
            {isSkeleton &&
              [...Array(numberOfEmptyRows)].map((item, i) => (
                <SkeletonTableRow numOfColumns={4} key={i} />
              ))}
          </tbody>
        </table>

        {this.props.list && this.props.list.rowCount > 0 && (
          <Pagination
            onPageSizeChange={this.handlePageSizeChange}
            pageSize={this.props.list.pageSize}
            currentPage={this.props.list.currentPage}
            linkUrlLeftPart={this.props.baseRoute}
            totalRecordsNumber={this.props.list.rowCount}
            pageCount={this.props.list.pageCount}
            authState={this.props.authState}
          />
        )}

        {isSkeleton && renderUpToDateModal(this.props.isDraftList)}
      </div>
    );
  }

  private handlePageSizeChange(pageNumber: number, itemPerPage: number): any {
    this.props.handlePageSizeChange(
      pageNumber,
      itemPerPage,
      this.props.list.sortOrder,
      this.props.authState,
      this.props.list.searchKeyword
    );
  }

  private sortOrderChanged(): any {
    let url = `${this.props.baseRoute}/${this.props.list.currentPage}`;
    if (
      this.props.list.sortOrder &&
      this.props.list.sortOrder.indexOf("-") > -1
    ) {
      url = `${url}?sortOrder=${responseListDefaultSort}`;
    } else {
      url = `${url}?sortOrder=-${responseListDefaultSort}`;
    }

    if (this.props.list.searchKeyword && this.props.list.searchKeyword !== "") {
      url = `${url}&searchKeyword=${this.props.list.searchKeyword}`;
    }

    appHistory.push(url);
  }
}

const renderUpToDateModal = (isDraft: boolean) =>
  !isDraft ? (
    <EmptyListModal
      title={i18n.t("responseList.emptyListHeadingMessage")}
      message={i18n.t("responseList.emptyListBodyMessage")}
    />
  ) : (
    <EmptyListModal
      title={i18n.t("draftResponseList.emptyListHeadingMessage")}
      message={i18n.t("draftResponseList.emptyListBodyMessage")}
    />
  );

const renderListItem = (item: Api.IResponseListViewModel, isDraft: boolean) => (
  <ResponseListRow response={item} key={item.id} isDraft={isDraft} />
);

export { Responses };
