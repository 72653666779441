import { FunctionComponent } from "react";
import { SourcesDocumentPage } from "./SourcesDocumentPage";
import { WithOktaAuthProps } from 'src/ts/Auth/duck/Models';
import { withOktaAuth } from "@okta/okta-react";
import * as React from "react";

 
const SourcesDocument: FunctionComponent<React.PropsWithChildren<WithOktaAuthProps>> = (props: WithOktaAuthProps) => {
    return <SourcesDocumentPage {...props}/>
}

export default withOktaAuth(SourcesDocument);
