import * as React from 'react';
import { WrappedFieldArrayProps } from 'redux-form';
import { Accordion } from '../Collapse/Accordion';
import { Collapse } from '../Collapse/Collapse';
import './Urls.scss';
import i18n from "../../../i18n";
import isURL from 'validator/lib/isURL';

export interface IUrlState {
  url: string,
  error: Error | null
}

export interface IUrlProps extends WrappedFieldArrayProps<any> {
  label: string,
  name: string,
  uid: string,
  tooltipText: string

}

class Urls extends React.Component<IUrlProps, IUrlState> {
  constructor(props: IUrlProps) {
    super(props);

    this.state = {
      url: '',
      error: null
    };

    this.addUrl = this.addUrl.bind(this);
    this.renderUrls = this.renderUrls.bind(this);
    this.handleUrlChange = this.handleUrlChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.removeUrl = this.removeUrl.bind(this);
  }

  public render() {
    return (
      <Accordion id={this.props.uid} className="urls-accordion">
        <Collapse id={`${this.props.uid}-collapse`} parentId={this.props.uid} title={this.props.label} tooltipText={this.props.tooltipText} showWandIcon={false} fetchData={() => { }} disableWandIcon={false }>
          <div className="response-urls">
          <div className="error" hidden={!this.state.error}>{this.state.error?.message}<br/></div>
              <div className="input-group add-urls">
                <input type="text" className="form-control" value={this.state.url} size={256} onKeyPress={this.handleKeyPress} onChange={this.handleUrlChange}/>
                <div className="input-group-append">
                  <button className={`btn btn-primary ${this.state.url ? '' : 'disabled'}`} type="button" onClick={this.addUrl}>Add</button>
                </div>
              </div>
              {this.props.fields.map(this.renderUrls)}
          </div>
        </Collapse>
      </Accordion>
    );
  }

  private handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if(e.key === 'Enter' && this.state.url !== '') {
      e.preventDefault();
      this.addUrl();
    }
  }

  private handleUrlChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      url: e.currentTarget.value,
      error: null
    });
  }

  private addUrl() {
    if (!this.state.url) {
      return;
    }
    const validOption = {
      require_tld: false, // enable localhost
      require_valid_protocol: false, // Allow all custom protocols like supportpoint://
      disallow_auth: true // Stop email addresses being accepted as URL's
    };

    if(this.state.url && this.state.url.length <= 256 && isURL(this.state.url, validOption)) {
      this.props.fields.push(this.state.url);
      this.setState({
        url: '',
        error: null
      });
    }
    else{
      this.setState({
        url: this.state.url,
        error: new Error(i18n.t("manage.metadata.invalidUrl"))
      });
    }
  }

  private removeUrl = (index: number) => () => {
    this.props.fields.remove(index);
  }

  private renderUrls(tag: string, index: number, fields: any) {
    let rawInput : string = fields.get(index);
    let url = /^[\w]+:\/\/(.*)/.test(rawInput)
      ? rawInput
      : `https://${rawInput}`
    return (
      <div className='url' key={index}>
        <a target='_blank' href={url}>{rawInput}</a>
        <i className='fa fa-times-circle' onClick={this.removeUrl(index)} />
      </div>
    )
  }
}

export {Urls}
