import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { InvalidIntegerRedirector } from '../Shared/Util/InvalidIntegerRedirector';
import { HomePage }  from './container/HomePage';
import './Home.scss';

interface IHomePageProps extends RouteComponentProps<any> {
  pageNumber: string;
}

export const Home: React.FC<IHomePageProps> = (props: IHomePageProps) =>
  (
    <InvalidIntegerRedirector numberStrings={[props.match.params.pageNumber]}>
      <HomePage {...{ currentPage: parseInt(props.match.params.pageNumber, 10), activeRoute: props.match.url }} />
    </InvalidIntegerRedirector>
  );
