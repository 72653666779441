import { FunctionComponent } from "react";
import { SourcesPage } from "./container/SourcesPage";
import { WithOktaAuthProps } from 'src/ts/Auth/duck/Models';
import { withOktaAuth } from "@okta/okta-react";

 
const Sources: FunctionComponent<React.PropsWithChildren<WithOktaAuthProps>> = (props: WithOktaAuthProps) => {
    return <SourcesPage {...props}/>
}

export default withOktaAuth(Sources);
