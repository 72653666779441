import * as classNames from 'classnames';
import * as React from 'react';
import { Tooltip } from 'react-tooltip';
import { DORCTooltip } from '../../../Shared/Tooltip';

interface ICollapseProps {
  title: string,
  id: string,
  parentId: string,
  tooltipText?: string,
  wandIconTooltipText?: string
  showWandIcon: boolean,
  fetchData: Function,
  disableWandIcon: boolean
}

interface ICollapseState {
  show: boolean;
}

export class Collapse extends React.Component<React.PropsWithChildren<ICollapseProps>, ICollapseState> {
  constructor(props: ICollapseProps) {
    super(props);

    this.state = {
      show: true
    };

    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  handleWandIconClick = (e: any) => {
    e.preventDefault();
    if (!this.props.disableWandIcon) {
      this.props.fetchData(3);
    }
  }

  public componentDidUpdate() {
    // Tooltip.rebuild();
  }

  public render() {
    return (
      <div className="card">
        <div className="card-header" id={`heading${this.props.id}`}>
          <h5 className="mb-0">
            <div className="header-text">
              {this.props.title}
              {this.props.tooltipText &&
                <DORCTooltip bootstrapIcon="icon-info-circle" tooltipText={this.props.tooltipText} placement="right" type="info"
                  iconClass="msg-tooltip-icon" triggerEvent="click" tooltipClass="cc-tooltip"/>
              }
              {this.props.showWandIcon && !this.props.disableWandIcon &&
                <div className="wand-icon-blue">
                  <img className="wandicon margin-8" src="/images/wandicon.svg" alt="wand icon" onClick={this.handleWandIconClick} />
                </div>
              }
              {this.props.showWandIcon && this.props.disableWandIcon &&
                <>
                  <div data-tooltip-id={`${this.props.id}-default-tooltip`} className="wand-icon-disabled" data-tooltip-content={this.props.wandIconTooltipText}>
                    <span className={"msg-tooltip-icon"}>
                      <img className="margin-8" src="/images/DisabledWandicon.svg" alt="wand icon" onClick={this.handleWandIconClick} />
                    </span>
                  </div>
                  <Tooltip id={`${this.props.id}-default-tooltip`} place={"right"} variant={"info"} className={"cc-tooltip"}
                    openOnClick={true} />
                </>
              }
            </div>
            <i className={classNames({ "fa fa-minus": this.state.show }, { "fa fa-plus": !this.state.show })}
              data-bs-toggle="collapse"
              data-target={`#${this.props.id}`}
              onClick={this.toggleCollapse}
              aria-expanded="true" aria-controls="collapseOne" />
          </h5>
        </div>

        <div id={this.props.id} className={`collapse${this.state.show ? ' show' : ''}`} aria-labelledby={`heading${this.props.id}`} >
          <div className="card-body">
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }

  private toggleCollapse() {
    this.setState((state, props) => {
      return {
        show: !state.show
      }
    })
  }
}

