import update from "immutability-helper";
import { SearchResponseActionTypes, SearchResponsesAction } from "./Actions";
import { ISearchResponseState } from "./Models";

const initialState: ISearchResponseState = {
  failed: false,
  fetched: false,
  fetching: false,
  error: '',
  docId: 0,
  isDocumentIdValid: true,
  responses: {
    firstRowOnPage: 0,
    lastRowOnPage: 10,
    pageCount: 0,
    pageSize: 10,
    rowCount: 0,
    currentPage: 1,
    sortOrder: '',
    searchKeyword: '',
    results: []
  },
  externalSourcesResponses: {
    data: [],
    totalCount: 0,
    pageSize: 0,
    currentPage: 0,
    totalPages: 0
  },
  query: '',
  externalSourcesFailed: false,
  externalSourcesFetched: false,
  externalSourcesFetching: false,
  isQueryValid: false
};

export const SearchResponseReducer = (
  state = initialState,
  action: SearchResponsesAction
): ISearchResponseState => {
  switch (action.type) {
    case SearchResponseActionTypes.InitialiseResponseSearchState:
      return update(state, {$set: initialState});
    case SearchResponseActionTypes.SearchingResponses:
      return update(state, {
        fetched: { $set: false },
        fetching: { $set: true },
        failed: { $set: false },
        error: { $set: '' }
      });
    case SearchResponseActionTypes.SearchResponsesFailed:
      return update(state, {
        error: { $set: action.payload.error },
        fetched: { $set: false },
        fetching: { $set: false },
        failed: { $set: true },
        responses: { $set: initialState.responses }
      });
    case SearchResponseActionTypes.SearchedResponses:
      return update(state, {
        fetched: { $set: true },
        fetching: { $set: false },
        failed: { $set: false },
        error: { $set: '' },
        isDocumentIdValid: {$set: action.payload.isDocumentIdValid},
        docId: { $set: action.payload.docId },
        responses: { $set: { ...action.payload.responses } },
        externalSourcesResponses: { $set: initialState.externalSourcesResponses }
      });
    case SearchResponseActionTypes.SearchingResponsesForExternalSources:
      return update(state, {
        externalSourcesFetched: { $set: false },
        externalSourcesFetching: { $set: true },
        externalSourcesFailed: { $set: false },
        error: { $set: '' }
      });
    case SearchResponseActionTypes.SearchResponsesFailedForExternalSources:
      return update(state, {
        error: { $set: action.payload.error },
        externalSourcesFetched: { $set: false },
        externalSourcesFetching: { $set: false },
        externalSourcesFailed: { $set: true },
        externalSourcesResponses: { $set: initialState.externalSourcesResponses }
      });
    case SearchResponseActionTypes.SearchedResponsesForExternalSources:
      return update(state, {
        externalSourcesFetched: { $set: true },
        externalSourcesFetching: { $set: false },
        externalSourcesFailed: { $set: false },
        error: { $set: '' },
        externalSourcesResponses: {
          $set: {
            data: action.payload.externalSourcesResponses.data, totalCount: action.payload.externalSourcesResponses.metadata.totalCount,
            pageSize: action.payload.externalSourcesResponses.metadata.pageSize,
            currentPage: action.payload.externalSourcesResponses.metadata.currentPage,
            totalPages: action.payload.externalSourcesResponses.metadata.totalPages
          }
        },
        responses: { $set: initialState.responses },
        isQueryValid: { $set: action.payload.externalSourcesResponses.data.length > 0 }
      });
    case SearchResponseActionTypes.DefaultResponsesStateForResponses:
      return update(state, {
        fetched: { $set: false },
        fetching: { $set: false },
        failed: { $set: false },
        responses: { $set: initialState.responses }
      });
    case SearchResponseActionTypes.DefaultResponsesStateForExternalSources:
      return update(state, {
        externalSourcesFetched: { $set: false },
        externalSourcesFetching: { $set: false },
        externalSourcesFailed: { $set: false },
        externalSourcesResponses: { $set: initialState.externalSourcesResponses }
      });
    default:
      return state;
  }
};
