import * as qs from 'qs';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { InvalidIntegerRedirector } from '../Shared/Util/InvalidIntegerRedirector';
import { ResponseListPage } from './container/ResponseListPage';
import { WithOktaAuthProps } from '../Auth/duck/Models';

interface IResponsesProps extends RouteComponentProps<any> {
  pageNumber: string;
  auth: WithOktaAuthProps
}


export const Responses: React.FC<IResponsesProps> = (props: IResponsesProps) => {
  const searchParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  return (
    <InvalidIntegerRedirector numberStrings={[props.match.params.pageNumber]}>
          <ResponseListPage
            {...{ currentPage: parseInt(props.match.params.pageNumber, 10), sortOrder: searchParams.sortOrder, searchKeyword: searchParams.searchKeyword }}/>
    </InvalidIntegerRedirector>
  );
}

