import update from "immutability-helper";
import { ManageAction, ManageActionTypes } from "./Actions";
import { IGeneratedResponseVariations, IManageResponseFormValues, IManageResponseState, ISection } from "./Models";

export const initialState: IManageResponseState = {
  id: '',
  title: '',
  category: '',
  primaryQuery: '',
  queryVariations: [],
  panvivaDocumentSections: [],
  panvivaDocumentId: "",
  panvivaDocumentVersion: 0,
  panvivaDocumentTitle: '',
  responseSaved: false,
  categories: [],
  fetchedCategories: false,
  fetchingCategories: false,
  failedCategories: false,
  fetchedResponse: false,
  fetchingResponse: false,
  failedResponse: false,
  fetchedDocumentSections: false,
  fetchingDocumentSections: false,
  failedDocumentSections: false,
  panvivaDocumentNotFound: false,
  fetchedChannels: false,
  fetchingChannels: false,
  fetchChannelsFailed: false,
  fetchedMetadata: false,
  fetchingMetadata: false,
  fetchMetadataFailed: false,
  copiedDocumentSections: [],
  savingResponse: false,
  failedSavingResponse: false,
  initialFormValues: null,
  error: "",
  disableSave: false,
  deletingResponse: false,
  deletedResponse: false,
  deleteResponseFailed: false,
  channels: [],
  metadata: [],
  draftResponseSaved: false,
  isSectionDeleted: false,
  checkingVersionMismatch: false,
  checkedVersionMismatch: false,
  checkVersionMismatchFailed: false,
  versionMismatch: false,
  fetchedContentCuration: false,
  fetchingContentCuration: false,
  fetchContentCurationFailed: false,
  fetchedResponseVariation: false,
  fetchingResponseVariation: false,
  fetchResponseVariationFailed: false,
  refreshResponseVariationIndexes: [],
  auth: null,
  fetchedKeywords: false,
  fetchingKeywords: false,
  fetchKeywordsFailed: false,
  fetchedQueryVariations: false,
  fetchingQueryVariations: false,
  fetchQueryVariationsFailed: false,
  fetchSummarySuccess: false,
  fetchSummaryPending: false,
  fetchSummaryFailed: false,
  documentId: 0,
  artefactId: "",
  errorAIDetails: { title: "", message: "", html: {} },
  undoSections: [],
  undoSectionOperationCompleted: false,
  undoResponses: [],
  undoResponseOperationCompleted: false,
  responseAddedAfterContentCuration: false,
  responseAddedbySummarizeButton: false,
  responseAddedByCopySections:false
};

export const ManageResponseReducer = (
  state = initialState,
  action: ManageAction
): IManageResponseState => {
  try {
    switch (action.type) {
      case ManageActionTypes.InitialisePage:
        return { ...initialState };
      case ManageActionTypes.FetchPanvivaDocumentPending:
        return update(state, {
          fetchedDocumentSections: { $set: false },
          fetchingDocumentSections: { $set: true },
          failedDocumentSections: { $set: false },
          panvivaDocumentNotFound: { $set: false },
          error: { $set: '' }
        });
      case ManageActionTypes.FetchResponseCategoriesPending:
        return update(state, {
          fetchedCategories: { $set: false },
          fetchingCategories: { $set: true },
          failedCategories: { $set: false },
          error: { $set: '' }
        });
      case ManageActionTypes.SavingResponse:
        return update(state, {
          savingResponse: { $set: true },
          error: { $set: '' }
        });

      case ManageActionTypes.FetchPanvivaDocumentRejected:
        return update(state, {
          error: { $set: action.payload.error },
          fetchedDocumentSections: { $set: false },
          fetchingDocumentSections: { $set: false },
          failedDocumentSections: { $set: true },
          disableSave: { $set: true }
        });
      case ManageActionTypes.FetchResponseCategoriesRejected:
        return update(state, {
          error: { $set: action.payload.error },
          fetchedCategories: { $set: false },
          fetchingCategories: { $set: false },
          failedCategories: { $set: true }
        });
      case ManageActionTypes.SaveResponseFailed:
        return update(state, {
          error: { $set: action.payload.error },
          savingResponse: { $set: false },
          failedSavingResponse: { $set: true }
        });
      case ManageActionTypes.FetchedPanvivaDocument:
        const disableSave = state.disableSave
          || action.payload.document.sections.length <= 0;

        return update(state, {
          fetchedDocumentSections: { $set: true },
          fetchingDocumentSections: { $set: false },
          failedDocumentSections: { $set: false },
          error: { $set: '' },
          panvivaDocumentId: { $set: action.payload.document.id },
          panvivaDocumentVersion: { $set: action.payload.document.version },
          panvivaDocumentSections: { $set: action.payload.document.sections.map(mapSectionFromApiSection) },
          disableSave: { $set: disableSave },
          panvivaDocumentTitle: { $set: action.payload.documentTitle },
          undoSections: { $set: [action.payload.document.sections.map(mapSectionFromApiSection)] },
          undoResponses: { $set: [""]}
        });
      case ManageActionTypes.FetchedResponseCategories:
        return update(state, {
          fetchedCategories: { $set: true },
          fetchingCategories: { $set: false },
          failedCategories: { $set: false },
          error: { $set: '' },
          categories: { $set: action.payload.categories }
        });
      case ManageActionTypes.SavedResponse:
        return update(state, {
          savingResponse: { $set: false },
          error: { $set: '' },
          responseSaved: { $set: action.payload.saved }
        });
      case ManageActionTypes.SetCopiedDocumentSections:
        let newResponseBody = action.payload.formValues?.primaryResponse ? action.payload.formValues.primaryResponse.trim() : '';
        const selectedSections = action.payload.formValues?.taggedSections ? action.payload.formValues.taggedSections : [];
  
        const uncopiedSections = selectedSections.filter(section => action.payload.oldSections.findIndex(foundSection => section.sectionId === foundSection.sectionId) < 0);

        if(uncopiedSections.length > 0){
        newResponseBody += `${newResponseBody === '' || uncopiedSections.length === 0 ? '' : '\n\n'}` +
          `${uncopiedSections.map(section => section.plainContent ? section.plainContent.trim() : '').join('\n\n')}`;

        let copiedDocumentUndoResponses = JSON.parse(JSON.stringify(state.undoResponses));
        copiedDocumentUndoResponses.push(newResponseBody);

        return update(state, {
          copiedDocumentSections: { $set: action.payload.formValues.taggedSections },
          undoResponses: { $set: copiedDocumentUndoResponses },
          responseAddedByCopySections:{$set: true}
        });
        }else{
          return update(state, {
            copiedDocumentSections: { $set: action.payload.formValues.taggedSections }
          });
        }
      case ManageActionTypes.FetchResponsePending:
        return update(state, {
          fetchedResponse: { $set: false },
          fetchingResponse: { $set: true },
          failedResponse: { $set: false },
          error: { $set: "" }
        });
      case ManageActionTypes.FetchedResponse:
        const formValues: IManageResponseFormValues = mapResponseToFormValues(action.payload.responseValues, state.panvivaDocumentSections, state.metadata);
          let parsedPanvivaDocumentSection: ISection[] = JSON.parse(JSON.stringify(state.panvivaDocumentSections));

        let checkedPanvivaDocumentSection = parsedPanvivaDocumentSection.map((x) => {
          let filteredSections = formValues?.taggedSections?.filter(t => t.sectionId == x.sectionId);
          if (formValues.taggedSections.map((x) => x.sectionId).includes(x.sectionId)) {
            x.checked = true;
          } else if ((filteredSections && filteredSections.length > 0)) {
            if (filteredSections[0].checked) {
              x.checked = filteredSections[0].checked;
            }
          }
          return x;
        });

        return update(state, {
          fetchedResponse: { $set: true },
          fetchingResponse: { $set: false },
          failedResponse: { $set: false },
          error: { $set: "" },
          initialFormValues: { $set: formValues },
          copiedDocumentSections: { $set: formValues.taggedSections },
          isSectionDeleted: { $set: isAnyTaggedSectionDeleted(action.payload.responseValues, state.panvivaDocumentSections) },
          undoSections: { $set: [checkedPanvivaDocumentSection] },
          undoResponses: { $set: [formValues.primaryResponse] }
        });
      case ManageActionTypes.FetchResponseRejected:
        return update(state, {
          fetchedResponse: { $set: false },
          fetchingResponse: { $set: false },
          failedResponse: { $set: true },
          error: { $set: action.payload.error },
          initialFormValues: { $set: null }
        });
      case ManageActionTypes.PanvivaDocumentNotFound:
        return update(state, {
          fetchingDocumentSections: { $set: false },
          fetchedDocumentSections: { $set: false },
          failedDocumentSections: { $set: false },
          panvivaDocumentNotFound: { $set: true },
          disableSave: { $set: true }
        });
      case ManageActionTypes.FetchedChannels:
        return update(state, {
          fetchedChannels: { $set: true },
          fetchingChannels: { $set: false },
          fetchChannelsFailed: { $set: false },
          error: { $set: "" },
          channels: { $set: action.payload.channels }
        });
      case ManageActionTypes.FetchChannelsFailed:
        return update(state, {
          fetchedChannels: { $set: false },
          fetchingChannels: { $set: false },
          fetchChannelsFailed: { $set: true },
          disableSave: { $set: true },
          error: { $set: action.payload.error },
          initialFormValues: { $set: null }
        });
      case ManageActionTypes.FetchChannelsPending:
        return update(state, {
          fetchedChannels: { $set: false },
          fetchingChannels: { $set: true },
          fetchChannelsFailed: { $set: false }
        });
      case ManageActionTypes.FetchedMetadata:
        const defaultMetadataValues: Api.IMetadatumValuesViewModel[] = action.payload.metadata.map(x => ({ id: x.id, values: [] }))
        return update(state, {
          fetchedMetadata: { $set: true },
          fetchingMetadata: { $set: false },
          fetchMetadataFailed: { $set: false },
          initialFormValues: { $set: { 
            category: "",
            title: "",
            primaryQuery: "",
            queryVariations: [],
            primaryResponse: "",
            responseVariations: [],
            taggedSections: [],
            metadata: defaultMetadataValues 
          } },
          error: { $set: "" },
          metadata: { $set: action.payload.metadata }
        });
      case ManageActionTypes.FetchMetadataFailed:
        return update(state, {
          fetchedMetadata: { $set: false },
          fetchingMetadata: { $set: false },
          fetchMetadataFailed: { $set: true },
          disableSave: { $set: true },
          error: { $set: action.payload.error },
          initialFormValues: { $set: null }
        });
      case ManageActionTypes.FetchMetadataPending:
        return update(state, {
          fetchedMetadata: { $set: false },
          fetchingMetadata: { $set: true },
          fetchMetadataFailed: { $set: false }
        });
      case ManageActionTypes.DeletingResponse:
        return update(state, {
          deletingResponse: { $set: true },
          deletedResponse: { $set: false },
          deleteResponseFailed: { $set: false },
          error: { $set: "" }
        });
      case ManageActionTypes.DeletedResponse:
        return update(state, {
          deletingResponse: { $set: false },
          deletedResponse: { $set: true },
          deleteResponseFailed: { $set: false },
          error: { $set: "" }
        });
      case ManageActionTypes.DeleteResponseFailed:
        return update(state, {
          deletingResponse: { $set: false },
          deletedResponse: { $set: false },
          deleteResponseFailed: { $set: true },
          error: { $set: action.payload.error }
        });
      case ManageActionTypes.DismissError:
        return update(state, {
          error: { $set: '' },
          errorAIDetails: { $set: initialState.errorAIDetails },
          deleteResponseFailed: { $set: false },
          failedSavingResponse: { $set: false },
          fetchContentCurationFailed: { $set: false },
          fetchResponseVariationFailed: { $set: false },
          fetchKeywordsFailed: { $set: false },
          fetchQueryVariationsFailed: { $set: false }
        });
      case ManageActionTypes.SavingDraftResponse:
        return update(state, {
          savingResponse: { $set: true },
          error: { $set: '' }
        });
      case ManageActionTypes.SaveDraftResponseFailed:
        return update(state, {
          error: { $set: action.payload.error },
          savingResponse: { $set: false },
          failedSavingResponse: { $set: true }
        });
      case ManageActionTypes.SavedDraftResponse:
        return update(state, {
          savingResponse: { $set: false },
          error: { $set: '' },
          draftResponseSaved: { $set: action.payload.saved }
        });
      case ManageActionTypes.DeletingDraftResponse:
        return update(state, {
          deletingResponse: { $set: true },
          deletedResponse: { $set: false },
          deleteResponseFailed: { $set: false },
          error: { $set: "" }
        });
      case ManageActionTypes.DeletedDraftResponse:
        return update(state, {
          deletingResponse: { $set: false },
          deletedResponse: { $set: true },
          deleteResponseFailed: { $set: false },
          error: { $set: "" }
        });
      case ManageActionTypes.DeleteDraftResponseFailed:
        return update(state, {
          deletingResponse: { $set: false },
          deletedResponse: { $set: false },
          deleteResponseFailed: { $set: true },
          error: { $set: action.payload.error }
        });
      case ManageActionTypes.CheckingVersionMismatch:
        return update(state, {
          checkingVersionMismatch: { $set: true },
          checkedVersionMismatch: { $set: false },
          checkVersionMismatchFailed: { $set: false },
          error: { $set: "" }
        });
      case ManageActionTypes.CheckedVersionMismatch:
        return update(state, {
          checkingVersionMismatch: { $set: false },
          checkedVersionMismatch: { $set: true },
          checkVersionMismatchFailed: { $set: false },
          versionMismatch: { $set: action.payload.versionMismatch },
          error: { $set: "" }
        });
      case ManageActionTypes.CheckVersionMismatchFailed:
        return update(state, {
          checkingVersionMismatch: { $set: false },
          checkedVersionMismatch: { $set: false },
          checkVersionMismatchFailed: { $set: true },
          error: { $set: action.payload.error }
        });

      case ManageActionTypes.FetchedContentCuration:
        let parsedPanvivaDocumentSections: ISection[] = JSON.parse(JSON.stringify(state.panvivaDocumentSections));
        let formValues1: IManageResponseFormValues = JSON.parse(JSON.stringify(action.payload.formValues));
        let checkedPanvivaDocumentSections = parsedPanvivaDocumentSections.map((x) => {
          let filteredSections = formValues1?.taggedSections?.filter(t => t.sectionId == x.sectionId);
          if (action.payload.taggedSections.includes(x.sectionId)) {
            x.checked = true;
          } else if ((filteredSections && filteredSections.length > 0)) {
            if (filteredSections[0].checked) {
              x.checked = filteredSections[0].checked;
            }
          }
          return x;
        });

        formValues1.primaryResponse = action.payload.primaryResponse;//formValues1.primaryResponse +"\n" + 
        formValues1.taggedSections = checkedPanvivaDocumentSections;
        let undoSections = JSON.parse(JSON.stringify(state.undoSections));
        if (JSON.stringify(state.undoSections[state.undoSections.length - 1]) !== JSON.stringify(checkedPanvivaDocumentSections)) {
          undoSections.push(checkedPanvivaDocumentSections);
        }

        let undoResponses = JSON.parse(JSON.stringify(state.undoResponses));
        if (state.undoResponses[state.undoResponses.length - 1] !== action.payload.primaryResponse) {
          undoResponses.push(action.payload.primaryResponse);
        }

        return update(state, {
          fetchedContentCuration: { $set: true },
          fetchingContentCuration: { $set: false },
          fetchContentCurationFailed: { $set: false },
          errorAIDetails: { $set: initialState.errorAIDetails },
          panvivaDocumentSections: { $set: checkedPanvivaDocumentSections },
          initialFormValues: { $set: formValues1 },
          undoSections: { $set: undoSections },
          undoResponses: { $set: undoResponses },
          responseAddedAfterContentCuration: { $set: true}
        });
      case ManageActionTypes.FetchContentCurationFailed:
        return update(state, {
          fetchedContentCuration: { $set: false },
          fetchingContentCuration: { $set: false },
          fetchContentCurationFailed: { $set: true },
          errorAIDetails: { $set: action.payload.errorAIDetails },
        });
      case ManageActionTypes.FetchContentCurationPending:
        return update(state, {
          fetchedContentCuration: { $set: false },
          fetchingContentCuration: { $set: true },
          fetchContentCurationFailed: { $set: false }
        });
      case ManageActionTypes.SetPanvivaDocumentSections:
        let panvivaDocumentUndoSections = JSON.parse(JSON.stringify(state.undoSections));
        if (JSON.stringify(state.undoSections[state.undoSections.length - 1]) !== JSON.stringify(action.payload.panvivaDocumentSections)) {
          panvivaDocumentUndoSections.push(action.payload.panvivaDocumentSections);
        }

        return update(state, {
          panvivaDocumentSections: { $set: action.payload.panvivaDocumentSections },
          undoSections: { $set: panvivaDocumentUndoSections }
        });
      case ManageActionTypes.FetchedResponseVariation:
        let formdata: IManageResponseFormValues = JSON.parse(JSON.stringify(action.payload.formValues));
        if (formdata?.responseVariations[action.payload.index]) {
          let responseVariation = formdata?.responseVariations[action.payload.index];
          responseVariation.content = action.payload.responseVariation;
          formdata.responseVariations[action.payload.index] = {
            channels: responseVariation.channels,
            content: responseVariation.content,
            id: responseVariation.id
          }
        }
        let refreshResponseVariationIndexes = JSON.parse(JSON.stringify(state.refreshResponseVariationIndexes)) as IGeneratedResponseVariations[];
        const existingIndex = refreshResponseVariationIndexes.filter(rp=> rp.index == action.payload.index)
        if(existingIndex && existingIndex.length > 0){
          existingIndex[0].channels = [...action.payload.channel];
        }
        else{
          refreshResponseVariationIndexes.push({
            index: action.payload.index,
            channels: action.payload.channel
          });
        }

        return update(state, {
          fetchedResponseVariation: { $set: true },
          fetchingResponseVariation: { $set: false },
          fetchResponseVariationFailed: { $set: false },
          error: { $set: "" },
          initialFormValues: { $set: JSON.parse(JSON.stringify(formdata)) },
          refreshResponseVariationIndexes: {$set : refreshResponseVariationIndexes}
        });
      case ManageActionTypes.FetchResponseVariationFailed:
        return update(state, {
          fetchedResponseVariation: { $set: false },
          fetchingResponseVariation: { $set: false },
          fetchResponseVariationFailed: { $set: true },
          errorAIDetails: { $set: action.payload.errorAIDetails },
        });
      case ManageActionTypes.FetchResponseVariationPending:
        return update(state, {
          fetchedResponseVariation: { $set: false },
          fetchingResponseVariation: { $set: true },
          fetchResponseVariationFailed: { $set: false }
        });
      case ManageActionTypes.ResetResponseVariation:
        return update(state, {
          fetchedResponseVariation: { $set: false },
          fetchingResponseVariation: { $set: false },
          fetchResponseVariationFailed: { $set: false },
        });
      case ManageActionTypes.RemoveRefreshResponseVariationIndex:
        let tempRefreshIndexes = [...state.refreshResponseVariationIndexes].filter(val => val.index !== action.payload.index);
        return update(state, {
          refreshResponseVariationIndexes: {$set : tempRefreshIndexes.map(val => val.index > action.payload.index ? {
              index: val.index -1
              ,channels: [...val.channels]
            }: val )}
        });
      case ManageActionTypes.FetchedKeywords:
        let keywordFormValues = JSON.parse(JSON.stringify(action.payload.formValues));
        const existingList = keywordFormValues.metadata[0].values;
        const existingListLowerCase = existingList.map((keyword:string) => keyword.toLowerCase());
        action.payload.keywords.forEach((keyword:string) => {
         !existingListLowerCase.includes(keyword.toLowerCase()) && existingList.push(keyword);
        });      
        keywordFormValues.metadata[0].values = existingList;

        return update(state, {
          fetchedKeywords: { $set: true },
          fetchingKeywords: { $set: false },
          fetchKeywordsFailed: { $set: false },
          error: { $set: "" },
          //keywords: { $set: action.payload.keywords },
          documentId: { $set: action.payload.documentId },
          artefactId: { $set: action.payload.artefactId },
          initialFormValues: { $set: keywordFormValues },
        });
      case ManageActionTypes.FetchKeywordsFailed:
        return update(state, {
          fetchedKeywords: { $set: false },
          fetchingKeywords: { $set: false },
          fetchKeywordsFailed: { $set: true },
          errorAIDetails: { $set: action.payload.errorAIDetails },
        });
      case ManageActionTypes.FetchKeywordsPending:
        return update(state, {
          fetchedKeywords: { $set: false },
          fetchingKeywords: { $set: true },
          fetchKeywordsFailed: { $set: false }
        });
      case ManageActionTypes.FetchedQueryVariations:
        let parsedFormdata: IManageResponseFormValues = JSON.parse(JSON.stringify(action.payload.formValues));
        let queryVariations = [];
        if (action.payload.numberToGenerate == 1) {
          queryVariations = JSON.parse(JSON.stringify(action.payload.existingVariations));
          queryVariations[action.payload.index] = action.payload.queryVariations[0];
        } else {
          const existingList = [...action.payload.existingVariations];
          const existingListLowerCase = existingList.map((variation:string) => variation.toLowerCase());
          action.payload.queryVariations.forEach((variation:string) => {
          !existingListLowerCase.includes(variation.toLowerCase()) && existingList.push(variation);
          // find empty row and push it to end of list
          const indexOfEmptyRow = existingList.findIndex((variation:string) => variation === "");
          existingList.push(existingList.splice(indexOfEmptyRow, 1)[0]);
          });
          queryVariations = [...existingList];
        }
        parsedFormdata.queryVariations = queryVariations;
        let ini1 = JSON.parse(JSON.stringify(state.initialFormValues));
        ini1.queryVariations = queryVariations
        return update(state, {
          fetchedQueryVariations: { $set: true },
          fetchingQueryVariations: { $set: false },
          fetchQueryVariationsFailed: { $set: false },
          error: { $set: "" },
          documentId: { $set: action.payload.documentId },
          artefactId: { $set: action.payload.artefactId },
          initialFormValues: { $set: parsedFormdata },
        });
      case ManageActionTypes.FetchQueryVariationsFailed:
        return update(state, {
          fetchedQueryVariations: { $set: false },
          fetchingQueryVariations: { $set: false },
          fetchQueryVariationsFailed: { $set: true },
          errorAIDetails: { $set: action.payload.errorAIDetails },
        });
      case ManageActionTypes.FetchQueryVariationsPending:
        return update(state, {
          fetchedQueryVariations: { $set: false },
          fetchingQueryVariations: { $set: true },
          fetchQueryVariationsFailed: { $set: false }
        });
      case ManageActionTypes.FetchSummarySuccess:
        let formCopy = JSON.parse(JSON.stringify(action.payload.formValues));
        formCopy.primaryResponse = action.payload.primaryResponse;
        let undoResponsesInFetchSummary = JSON.parse(JSON.stringify(state.undoResponses));
        if (state.undoResponses[state.undoResponses.length - 1] !== action.payload.primaryResponse) {
          undoResponsesInFetchSummary.push(action.payload.primaryResponse);
        }

        return update(state, {
          fetchSummarySuccess: { $set: true },
          fetchSummaryPending: { $set: false },
          fetchSummaryFailed: { $set: false },
          errorAIDetails: { $set: initialState.errorAIDetails },
          initialFormValues: { $set: formCopy },
          undoResponses: { $set: undoResponsesInFetchSummary },
          responseAddedbySummarizeButton: {$set:true}
        });
      case ManageActionTypes.FetchSummaryPending:
        return update(state, {
          fetchSummarySuccess: { $set: false },
          fetchSummaryPending: { $set: true },
          fetchSummaryFailed: { $set: false }
        });
      case ManageActionTypes.FetchSummaryFailed:
        return update(state, {
          fetchSummarySuccess: { $set: false },
          fetchSummaryPending: { $set: false },
          fetchSummaryFailed: { $set: true },
          errorAIDetails: { $set: action.payload.errorAIDetails }
        });
      case ManageActionTypes.ResetContentCuration:
        return update(state, {
          fetchedContentCuration: { $set: false },
          fetchingContentCuration: { $set: false },
          fetchContentCurationFailed: { $set: false }
        });
      case ManageActionTypes.ResetKeywords:
        return update(state, {
          fetchedKeywords: { $set: false },
          fetchingKeywords: { $set: false },
          fetchKeywordsFailed: { $set: false }
        });
      case ManageActionTypes.ResetQueryVariations:
        return update(state, {
          fetchedQueryVariations: { $set: false },
          fetchingQueryVariations: { $set: false },
          fetchQueryVariationsFailed: { $set: false }
        });
      case ManageActionTypes.SetUndoSection:
        let formValuesInUndoSection: IManageResponseFormValues = JSON.parse(JSON.stringify(action.payload.formValues));
        formValuesInUndoSection.taggedSections = JSON.parse(JSON.stringify(state.undoSections[state.undoSections.length - 2]));

        let undoSectionsInUndoSection = JSON.parse(JSON.stringify(state.undoSections));
        undoSectionsInUndoSection.pop();

        return update(state, {
          undoSections: { $set: undoSectionsInUndoSection },
          undoSectionOperationCompleted: { $set: true },
          initialFormValues: { $set: formValuesInUndoSection }
        });
      case ManageActionTypes.ResetDocumentSectionUndo:
        return update(state, {
          undoSectionOperationCompleted: { $set: false }
        });
      case ManageActionTypes.SetUndoResponse:
        let formValuesInUndoResponse: IManageResponseFormValues = JSON.parse(JSON.stringify(action.payload.formValues));
        formValuesInUndoResponse.primaryResponse = JSON.parse(JSON.stringify(state.undoResponses[state.undoResponses.length - 2]));

        let undoResponsesInUndoResponse = JSON.parse(JSON.stringify(state.undoResponses));
        undoResponsesInUndoResponse.pop();

        return update(state, {
          undoResponses: { $set: undoResponsesInUndoResponse },
          undoResponseOperationCompleted: { $set: true },
          initialFormValues: { $set: formValuesInUndoResponse }
        });
      case ManageActionTypes.SetDocumentResponse:
        let undoResponsesInDocumentResponse: string[] = JSON.parse(JSON.stringify(state.undoResponses));
        if (state.undoResponses[state.undoResponses.length - 1] !== action.payload.response && !action.payload.overrideCurrentResponse) {
          undoResponsesInDocumentResponse.push(action.payload.response);
        }

        if (action.payload.overrideCurrentResponse ) {
          if (undoResponsesInDocumentResponse.length == 1 && undoResponsesInDocumentResponse[undoResponsesInDocumentResponse.length - 1] == "") {
            undoResponsesInDocumentResponse.push(action.payload.response);
          } else {
            undoResponsesInDocumentResponse[undoResponsesInDocumentResponse.length - 1] = action.payload.response;
          }
        }

        return update(state, {
          undoResponses: { $set: undoResponsesInDocumentResponse },
          responseAddedAfterContentCuration: { $set: false },
          responseAddedbySummarizeButton: { $set: false },
          responseAddedByCopySections: { $set: false }
        });
      case ManageActionTypes.ResetDocumentResponseUndo:
        return update(state, {
          undoResponseOperationCompleted: { $set: false }
        });
      default:
        return state;
    }
  } catch {
    // TODO: Clean-up this ugly hack to prevent Jest initializing issues.
    return state;
  }
};

const mapResponseToFormValues = (res: Api.IGetDraftResponseResponse | Api.IGetResponseResponse, panvivaDocumentSections: ISection[], metadataFields: Api.IMetadataViewModel[]): IManageResponseFormValues => {
  const taggedSections = panvivaDocumentSections
    .filter(docSection => res.result.taggedPanvivaDocumentSections
      .some(resSection => resSection.sectionId === docSection.sectionId))
    .map((section, index) => ({
      ...section,
      checked: true,
      hasChanges: section.hash !== res.result.taggedPanvivaDocumentSections.find(tagSection => tagSection.sectionId === section.sectionId)!.hashCode,
      index
    }));

  const metadataValuesWithEmptyValues = res.result.metadata ? res.result.metadata : [];

  // metadata values need to match 1:1 with their fields because Redux Form works entirely by array index
  metadataFields.forEach((field) => {
    if (!res.result.metadata || !res.result.metadata.find(x => x.id === field.id)) {
      // So we're creating placeholder values for any that don't already exist
      metadataValuesWithEmptyValues.push({
        id: field.id,
        values: []
      })
    }
  })

  metadataValuesWithEmptyValues.sort((a, b) => a.id - b.id)
  let newqueryVariations:any=[];
  if(res.result.queryVariations){
    newqueryVariations=res.result.queryVariations.map(qv => qv.query);
    newqueryVariations.push("")
  }

  return {
    title: res.result.title,
    category: res.result.category,
    primaryQuery: res.result.primaryQuery,
    primaryResponse: res.result.responseContent,
    queryVariations: newqueryVariations,
    taggedSections,
    responseVariations: res.result.responseVariations ? res.result.responseVariations : [],
    metadata: metadataValuesWithEmptyValues
  };
}

const isAnyTaggedSectionDeleted = (res: Api.IGetDraftResponseResponse | Api.IGetResponseResponse, panvivaDocumentSections: ISection[]) => {
  return !res.result.taggedPanvivaDocumentSections.every((selectedSection) => {
    return panvivaDocumentSections.some((documentSection) => {
      return selectedSection.sectionId === documentSection.sectionId;
    });
  });
}

const mapSectionFromApiSection: (section: Api.IDocumentSection) => ISection = (section) => {
  return {
    ...section,
    checked: false
  }
}
