import * as classNames from 'classnames';
import * as React from 'react';
import './Modal.scss';

export interface IModalProps {
  children: any;
  onClickBackdrop?: () => any;
  visible: boolean;
  wrapperProps?: object;
  className?: string;
  dialogClassName?: string;
}

let modalsShowing = 0;

interface IModalState {
  visible: boolean;
  modalIndex: number;
  transitioning: boolean;
}

function modalWillShow() {
  if (modalsShowing === 0 && document) {
    document.body.classList.add('modal-open');
  }

  modalsShowing += 1;
}

function modalWillHide() {
  modalsShowing -= 1;

  if (modalsShowing === 0 && document) {
    document.body.classList.remove('modal-open');
  }
}

class Modal extends React.Component<IModalProps, IModalState> {
  constructor(props: IModalProps) {
    super(props);

    this.state = {
      visible: this.props.visible,
      modalIndex: 0,
      transitioning: false
    };
  }

  public componentDidMount = () => {
    if (this.props.visible) {
      modalWillShow();
    }
  }

  public componentDidUpdate = (prevProps: IModalProps) => {
    if (this.props.visible !== prevProps.visible) {
      if (this.props.visible) {
        modalWillShow();
      } else {
        modalWillHide();
      }

      this.setState({ transitioning: true, modalIndex: modalsShowing }, () => {
        window.requestAnimationFrame(() => {
          this.setState({ visible: this.props.visible }, () => {
            window.setTimeout(() => { this.setState({ transitioning: false }); }, 150);
          });
        });
      });
    }
  }

  public componentWillUnmount = () => {
    if (this.props.visible) {
      modalWillHide();
    }
  }

  public render = () => {
    const {
      wrapperProps,
      className,
      dialogClassName,
      visible,
      onClickBackdrop,
      children,
      ...other
    } = this.props;

    return (
      <div {...wrapperProps}>
        <div
          className={classNames('modal', 'fade', { show: this.state.visible }, className)}
          style={{
            display: ((this.state.visible || this.state.transitioning) ? 'block' : 'none'),
            zIndex: 1040 + this.state.modalIndex + 1
          }}
          role="dialog"
          aria-hidden={!this.state.visible}
          tabIndex={-1}
          onClick={onClickBackdrop}
          {...other}
        >
          <div className={classNames('modal-dialog', dialogClassName)} role="document" onClick={this.stopPropagation}>
            <div className="modal-content">
              {children}
            </div>
          </div>
        </div>
        {this.renderBackdrop()}
      </div>
    );
  }

  private stopPropagation = (event: React.FormEvent<HTMLDivElement>) => {
    event.stopPropagation();
  }

  private renderBackdrop = () => {
    if (this.state.visible || this.state.transitioning) {
      return (
        <div
          className={classNames('modal-backdrop', 'fade', { show: this.state.visible })}
          onClick={this.props.onClickBackdrop}
          role="presentation"
          style={{ zIndex: 1040 + this.state.modalIndex }}
        />
      );
    }

    return null;
  }
}

export { Modal };
