import { Action, Middleware } from 'redux';

export const actionToPlainObject: Middleware<{}, Action> = store => next => action => {
  if(isObjectLike(action)) {
    next(Object.assign({}, action) as Action);
  }
}

function isObjectLike(val: any) {
 // tslint:disable-next-line:triple-equals
 return typeof val == 'object' && val !== null;
}
