import {
  OktaAuth,
  AuthState,
  UserClaims,
} from "@okta/okta-auth-js";
import {
} from "@okta/okta-react";

import * as React from "react";
import i18n from "../../i18n";
import { TenantSelector } from "../TenantSelector/TenantSelector";
import "./Header.scss";
import { WithOktaAuthProps } from "src/ts/Auth/duck/Models";

const getName = (auth: AuthState): string => {
  if (auth.accessToken != null) {
    const user: UserClaims = JSON.parse(atob(auth.accessToken.accessToken.split(".")[1]));
    return user.sub;
  }
  return "";
};

const logout = (authService: OktaAuth) => {
  localStorage.removeItem('tableColumnSelection');
  authService.signOut({
    postLogoutRedirectUri: `${window.location.origin}/logout`
  });
};

const toggleMenu = () =>{
  const elements = [];
  var x = document.getElementById("MainMenu");
  var y = document.getElementById("CollapseMenu");
  elements.push(x, y)
  elements.forEach((el) => {
    if (el != null){
      if (el?.style.display === "none") {
        el.style.display = "inline";
      } else {
        el.style.display = "none";
      }
    }
  })
};

const expandSidebar = () => {
  // Expand the sidebar
  document.getElementById('Sidebar')?.classList.toggle('expanded');
};

export const UserMenu: React.FunctionComponent<WithOktaAuthProps> = (
  props: WithOktaAuthProps
) => (
  <React.Fragment>
    <div className="dropdown">
      <a
        className="dropdown-toggle"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i className="icon icon-user" />
        <span className="user-name">{getName(props.authState)}</span>
      </a>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

        <a
          className="dropdown-item"
          href="#"
          id="logoutBtn"
          aria-label="logoutBtn"
          onClick={
            // tslint:disable-next-line: jsx-no-lambda
            () => {
              logout(props.oktaAuth);
            }
          }
        >
          {i18n.t("base.header.logout")}
        </a>
      </div>
    </div>
  </React.Fragment>
);

export const Header: React.FunctionComponent<WithOktaAuthProps> = (
  props: WithOktaAuthProps
) => (
  
  <header>
    <div className="page-header">
      <div className="main-menu">
      <span id='MainMenu' className='icon icon-menu' title='Show more information'  onClick={
            // tslint:disable-next-line: jsx-no-lambda
            () => {
              expandSidebar();
              toggleMenu();
            }}></span>
      <span id='CollapseMenu' title='Show less information' className='fa fa-angle-double-left collapse-menu' style={{display:'none'}} onClick={
            // tslint:disable-next-line: jsx-no-lambda
            () => {
              expandSidebar();
              toggleMenu();
            }}></span>
      <span className="heading">{i18n.t("base.header.productName")}</span>
      </div>
      <div className="user-section">
      <a href="/" className="logo"></a>
        <div className="tenant-selector">
          {getName(props.authState) && <TenantSelector />}
        </div>
        {getName(props.authState) && <UserMenu {...props} />}
      </div>
    </div>
  </header>
);
