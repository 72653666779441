import * as qs from 'qs';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { InvalidIntegerRedirector } from '../Shared/Util/InvalidIntegerRedirector';
import { DraftListPage } from './container/DraftListPage';

interface IDraftsProps extends RouteComponentProps<any> {
  pageNumber: string;
}

export const DraftResponses: React.FC<IDraftsProps> = (props: IDraftsProps) => {
  const searchParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  return (
    <InvalidIntegerRedirector numberStrings={[props.match.params.pageNumber]}>
      <DraftListPage
        {...{ currentPage: parseInt(props.match.params.pageNumber, 10), sortOrder: searchParams.sortOrder }} />
    </InvalidIntegerRedirector>
  );
}

