import * as React from 'react';
import { WrappedFieldArrayProps } from 'redux-form';
import i18n from '../../../i18n';

import { IChannel, IGeneratedResponseVariations } from '../../duck/Models';
import { ResponseVariation } from './ResponseVariation';
import './ResponseVariations.scss';

interface IResponseVariationsState {
  availableChannels: IChannel[]
}

export interface IResponseVariationsProps extends WrappedFieldArrayProps<any> {
  channels: IChannel[];
  touch: (form: string, [...fields]: string) => any;
  disabledAIAssistant: boolean;
  showAIAssistant: boolean;
  fetchResponseVariation: Function;
  refreshResponseVariations: IGeneratedResponseVariations[];
  removeRefreshIndex: (index: number) =>any 
}

class ResponseVariations extends React.Component<IResponseVariationsProps, IResponseVariationsState> {
  constructor(props: IResponseVariationsProps) {
    super(props);

    this.addNewVariation = this.addNewVariation.bind(this);
    this.renderVariations = this.renderVariations.bind(this);
    this.addChannelToVariation = this.addChannelToVariation.bind(this);
    this.removeVariation = this.removeVariation.bind(this);
    this.removeChannelFromVariation = this.removeChannelFromVariation.bind(this);
    this.setAvailableChannels = this.setAvailableChannels.bind(this);

    this.setAvailableChannels(props, false);
  }



  public componentDidUpdate(oldProps: IResponseVariationsProps) {
    if (oldProps && (!oldProps.channels || (oldProps.channels !== this.props.channels))) {
      this.setAvailableChannels(this.props, true);
    }
  }

  public render() {
    return (
      <div className="response-variations">
        <div className="variations">
          { this.props.fields.map(this.renderVariations) }
        </div>
        <div className="add-new-variation">
          <button className="btn btn-outline-secondary"
            disabled={!this.state.availableChannels.length}
            type="button" onClick={this.addNewVariation}>
            <i className="fa fa-plus"/>
            {i18n.t("manage.responseVariations.addNew")}
          </button>
        </div>
      </div>
    );
  }

  private setAvailableChannels(props: IResponseVariationsProps, setState: boolean) {
    if(setState) {
      this.setState({
        availableChannels: props.channels
      });
    } else {
      this.state = {
        availableChannels: props.channels
      };
    }
  }

  private addNewVariation() {
    this.props.fields.push(
      {
        channels: [],
        content: ''
      }
    );
  }

  private renderVariations(variation: any, index: number, fields: any) {
    return (
      
      <ResponseVariation fields={fields} meta={this.props.meta}
        key={variation} index={index} variation={variation}
        availableChannels={[...this.state.availableChannels]}
        addChannel={this.addChannelToVariation}
        removeChannel={this.removeChannelFromVariation}
        removeVariation={this.removeVariation}
        disabledAIAssistant = {this.props.disabledAIAssistant}
        showAIAssistant = {this.props.showAIAssistant}
        fetchResponseVariation= {this.props.fetchResponseVariation}
        generatedResponseVariation = { this.props.refreshResponseVariations ? this.props.refreshResponseVariations.filter(rv=> rv.index === index)[0]: undefined }
        removeRefreshIndex= {this.props.removeRefreshIndex}
   />
    )
  }

  private addChannelToVariation (channel: Api.IChannelViewModel, variationKey: string) {
    this.props.touch('manageForm', variationKey);
    if(channel) {
      this.setState({
        availableChannels: [...this.state.availableChannels.filter(x => x.id !== channel.id)]
      });
    }
  }

  private removeChannelFromVariation(channel: Api.IChannelViewModel, variationKey: string) {
    this.props.touch('manageForm', variationKey);
    if(channel) {
      this.setState({
        availableChannels: this.state.availableChannels.concat([channel])
      });
    }
  }

  private removeVariation = (index: number) => () => {
    const variation = this.props.fields.get(index);
    if(index > -1) {
      this.setState({
        availableChannels: this.state.availableChannels.concat(variation.channels)
      })

      this.props.fields.remove(index);
      this.props.removeRefreshIndex(index);
    }

  }
}

export { ResponseVariations }
