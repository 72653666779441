import { FunctionComponent } from "react";
import i18n from "../../../i18n";
import { WithOktaAuthProps } from "src/ts/Auth/duck/Models";
import '../SourcesPage.scss';
 
export const SourcesDocumentPage: FunctionComponent<WithOktaAuthProps> = (props: WithOktaAuthProps) => {
    return (
        <div className="sources-container">
            <h3 className="sources-title">{i18n.t("sources.heading")}</h3>
        </div>);
}