import * as React from 'react';
import './CardPage.scss';

export const CardPage: React.FC<React.PropsWithChildren<{pageTitle: string, className?: string}>> = (props) => (
  <div className={"card-page " + props.className}>
    <div className="page-title">
      <h1>{props.pageTitle}</h1>
    </div>
    <div className="card-page-card">
      {props.children}
    </div>
  </div>
);
