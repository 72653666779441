import { UserClaims  } from '@okta/okta-auth-js';
import * as React from 'react';
import { useEffect, useState, FunctionComponent } from 'react';
import { useSelector } from "react-redux";
import { LoadingIndicator } from '../../Shared/LoadingIndicator';
//import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import { Sidebar } from '../Sidebar/Sidebar';
import { WithOktaAuthProps } from 'src/ts/Auth/duck/Models';
import { withOktaAuth } from '@okta/okta-react';
import { AlertBar, Card, LinearProgress, UpIcon } from '@panviva/panviva-react-ui';
import { AlertColor, IStoreState } from 'src/ts/Model';
import i18n from '../../../ts/i18n';

const PageLayout:FunctionComponent<React.PropsWithChildren<WithOktaAuthProps>> = (props) =>{

  const analyticsDetails = useSelector((state: IStoreState) => state.analytics);
  
  const [user, setUser] = useState<UserClaims | null>(null);
  const [notification, setNotification] = useState<{
    text: string;
    severity: AlertColor;
  }>();

  useEffect(()=>{
    if (user === null) {
      getUser().then(response => setUser(response));
    }
  },[])

  useEffect(()=>{
      analyticsDetails?.csvExport?.fetchedCSV && setNotification({text: i18n.t("analytics.exportSuccess"), severity: 'success'});
      analyticsDetails?.csvExport?.fetchFailedCSV && setNotification({text: i18n.t("analytics.exportError"), severity: 'error'});
      
      const timeId = setTimeout(() => {
        // After 3 seconds clear success/failure notification
        setNotification(undefined);
      }, 3000);

      return () => {
        clearTimeout(timeId);
      }

  },[analyticsDetails?.csvExport])

  const getUser = async() => {
    const oktaUser = props.authState?.isAuthenticated
      ? await props.oktaAuth.getUser()
      : undefined;
    return oktaUser || null;
  }

  const closeNotification = () => {
    setNotification(undefined);
  };

  if (!props.authState) {
    return <LoadingIndicator busy={true} />
  }
  if (props.authState.error) {
    throw props.authState.error;
  }

  return (
    <React.Fragment>
      <div className="container-fluid page-wrapper">
        <Header {...props}/>
        <div className="main-body container-fluid">
          <Sidebar />
          {props.children}
        </div>
        { analyticsDetails?.csvExport?.fetchingCSV?.inProgress &&
                <Card className='progresss_container'>
                  
                  <div className='progress_header'>
                    <UpIcon icon='info'/>
                    <span>{i18n.t("analytics.exportInProgress")}</span>
                  </div>

                  <div className='progress_status'>
                    <div>{i18n.t("analytics.exportInfo")}</div>
                    <LinearProgress determinate showAnimation={false} completed={analyticsDetails?.csvExport?.fetchingCSV.percentage}/>
                    <div>{`${Math.round(analyticsDetails?.csvExport?.fetchingCSV.percentage)}% completed`}</div>
                  </div>
                  
                </Card>
            }
            { notification && (
                <AlertBar
                    active
                    className='alert_container'
                    message={notification.text}
                    status={notification.severity}
                    onClose={closeNotification}
                />
            )}
        {/* <Footer /> */}
      </div>
    </React.Fragment>
  );

}

export default withOktaAuth(PageLayout);
