import * as React from 'react';
import { Link } from 'react-router-dom';
import { CardPage } from '../Shared/CardPage/CardPage';
import './ErrorPage.scss';

export const ErrorPageContent: React.FC<{errorTitle: string, errorMessage: string}> = (props) => (
  <CardPage pageTitle={props.errorTitle} className="error-page">

    <div className="error-icon">
      <i className="fa fa-oil-can" />
      <i className="fa fa-tint" />
      <i className="icon icon-cog fa-spin cog-lg" />
      <i className="icon icon-cog fa-spin cog-sm" />
    </div>
    <p>{props.errorMessage}</p>
    <Link className="btn btn-lg btn-primary back-btn" to="/">Back to home</Link>
  </CardPage>
);
