import {  AuthState } from "@okta/okta-auth-js";
import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";
import { validateAuthState } from "../../Auth/AuthHelper";
import i18n from "../../i18n";
import { IStoreState } from "../../Model";
import { Error } from "../../Shared/Error";
import { LoadingIndicator } from "../../Shared/LoadingIndicator";
import Pagination from "../../Shared/Pagination/Index";
import { UpdateList } from "../components/Updates";
import { getUpdateListThunk } from "../duck/Actions";
import { WithOktaAuthProps } from "src/ts/Auth/duck/Models";
import { withOktaAuth } from "@okta/okta-react";

export interface IUpdateStateProps {
  failed: boolean;
  fetched: boolean;
  fetching: boolean;
  updates: Api.IPagedResult<Api.INotificationViewModel>;
}

interface IUpdatePageDispatchProps {
  fetchUpdateList: (pageNumber: number, itemPerPage: number, auth: AuthState | null) => any;
}

interface IUpdatePageOwnProps { currentPage: number };


type IUpdateProps = IUpdateStateProps & IUpdatePageDispatchProps & IUpdatePageOwnProps & WithOktaAuthProps;

class UpdatePage extends React.Component<IUpdateProps> {
  constructor(props: IUpdateProps) {
    super(props);
  }

  public componentDidMount() {
    if (this.props.authState?.isAuthenticated) {
      this.props.fetchUpdateList(this.props.currentPage, this.props.updates.pageSize, this.props.authState);
    }
    else {
      validateAuthState(this.props.authState);
    }
  }

  public componentDidUpdate(prevProps: IUpdateProps) {
    if (this.props.authState?.isAuthenticated) {
      if (prevProps.currentPage !== this.props.currentPage) {
        this.props.fetchUpdateList(this.props.currentPage, this.props.updates.pageSize, this.props.authState);
      }
      else if (prevProps.authState === null && this.props.authState?.isAuthenticated) {
        this.props.fetchUpdateList(this.props.currentPage, this.props.updates.pageSize, this.props.authState);
      }
    }
    else {
      validateAuthState(this.props.authState);
    }
  }

  public render() {
    if (!this.props.authState) {
      return <LoadingIndicator busy={true} />
    }
    if (this.props.authState?.error) {
      throw this.props.authState.error;
    }
    return (
      <React.Fragment>
        {this.props.failed && <Error message={i18n.t("home.fetchingUpdatesFailed")} />}
        <LoadingIndicator busy={this.props.fetching} />
        {this.props.fetched && <UpdateList updates={this.props.updates} />}
        {this.props.fetched && this.props.updates.rowCount > 0 && <Pagination onPageSizeChange={this.props.fetchUpdateList}
          pageSize={this.props.updates.pageSize}
          currentPage={this.props.currentPage}
          linkUrlLeftPart="/home/updates" totalRecordsNumber={this.props.updates.rowCount}
          pageCount={this.props.updates.pageCount}
          authState={this.props.authState} />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IStoreState) => {
  return {
    failed: state.updates.failed,
    fetched: state.updates.fetched,
    fetching: state.updates.fetching,
    updates: state.updates.updates
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchUpdateList: (pageNumber: number, itemPerPage: number, auth: AuthState) => {
      dispatch(getUpdateListThunk(pageNumber, itemPerPage, auth));
    },
  };
};

const hoc = compose(withOktaAuth, connect<IUpdateStateProps, IUpdatePageDispatchProps, IUpdatePageOwnProps>(mapStateToProps, mapDispatchToProps))(UpdatePage);

export { hoc as UpdatePage };
