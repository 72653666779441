import { AuthState } from '@okta/okta-auth-js';
import * as DOMPurify from 'dompurify';
import { Server } from "../../Shared/Util/Server";
import { DocumentContent } from '../container/ManageResponsePage.model';
import { IManageResponseFormValues, ISection, IContentCurationResponse, IResponseVariationResponse, IKeywordsResponse, IQueryVariationsResponse, ISummaryResponse } from "./Models";

export function fetchPanvivaDocument(documentId: number, auth: AuthState): Promise<Api.IGetPanvivaDocumentResponse | null> {
  return Server.get<Api.IGetPanvivaDocumentResponse>(`/panvivadocument/${documentId}`, auth).then((res) => {
    if (!res.documentNotFound && res.document && res.document.sections) {
      return {
        ...res,
        document: {
          ...res.document,
          sections: res.document.sections.map((section) => {
            return {
              ...section,
              richContent: DOMPurify.sanitize(section.richContent, {
                ALLOWED_ATTR: ['class', 'src', 'draggable'],
                ALLOWED_TAGS: ['p', 'ul', 'ol', 'nl', 'li', 'br', 'div', 'span', 'table', 'thead', 'tbody', 'tr', 'th', 'td', 'img'],
                KEEP_CONTENT: true
              })
            }
          })
        }
      };
    }
    return res;
  });
}

export function fetchChannels(auth: AuthState): Promise<Api.IChannelsResponse | null> {
  return Server.get<Api.IChannelsResponse>(`/referencedata/channels`, auth);
}

export function fetchMetadata(auth: AuthState): Promise<Api.IMetadataResponse | null> {
  return Server.get<Api.IMetadataResponse>(`/referencedata/metadata`, auth);
}

export function fetchKeywords(artefactId: string, documentId: number, artefactContent: string, existingKeywords: string[], auth: AuthState): Promise<IKeywordsResponse | null> {
  const completedValues: Api.IKeywordRequest = { artefactId: artefactId, documentId: documentId, artefactContent: artefactContent, existingKeywords: existingKeywords };

  return Server.post<IKeywordsResponse>(`/assistant/generate/keywords`, auth, completedValues);
}

export function fetchQueryVariations(artefactId: string, documentId: number, primaryQuery: string, numberToGenerate: number, existingVariations: string[], auth: AuthState): Promise<IQueryVariationsResponse | null> {
  const completedValues: Api.IQueryVariationsRequest = { artefactId: artefactId, documentId: documentId, primaryQuery: primaryQuery, numberToGenerate: numberToGenerate, existingVariations: existingVariations };

  return Server.post<IQueryVariationsResponse>(`/assistant/generate/queryvariations`, auth, completedValues);
}

export function fetchResponseCategories(auth: AuthState): Promise<Api.ICategoriesResponse | null> {
  return Server.get<Api.ICategoriesResponse>(`/referencedata/categories`, auth);
}

export function fetchResponse(responseId: string, auth: AuthState): Promise<Api.IGetResponseResponse | null> {
  return Server.get<Api.IGetResponseResponse>(`/response/${responseId}`, auth);
}

export function fetchDraftResponse(responseId: string, auth: AuthState): Promise<Api.IGetDraftResponseResponse | null> {
  return Server.get<Api.IGetDraftResponseResponse>(`/draftresponse/${responseId}`, auth);
}

export function fetchHasDraftResponse(responseId: string, auth: AuthState | null): Promise<Api.IHasDraftResponseResponse> {
  if (auth == null ) {
    throw Error
  }
  return Server.get<Api.IHasDraftResponseResponse>(`/response/draft/${responseId}`, auth);
}

export function fetchCheckForVersionMismatch(responseId: string, auth: AuthState): Promise<Api.ICheckForVersionMismatchResponse> {
  return Server.get<Api.ICheckForVersionMismatchResponse>(`/draftresponse/versionCheck/${responseId}`, auth);
}

export function createResponse(values: IManageResponseFormValues, panvivaDocumentId: string, panvivaDocumentVersion: number, auth: AuthState): Promise<Api.ICreateResponseResponse | null> {
  const completedValues: Api.ICreateResponseRequest = { ...mapValuesToSaveRequest(values, panvivaDocumentVersion), panvivaDocumentId };
  return Server.post<Api.ICreateResponseResponse>(`/response`, auth, completedValues);
}

export function editResponse(values: IManageResponseFormValues, responseId: string, panvivaDocumentVersion: number, auth: AuthState): Promise<Api.IUpdateResponseResponse> {
  const completedValues: Api.IUpdateResponseRequest = mapValuesToSaveRequest(values, panvivaDocumentVersion);
  return Server.put<Api.IUpdateResponseResponse>(`/response/${responseId}`, auth, completedValues);
}

export function deleteResponse(responseId: string, auth: AuthState): Promise<Api.IDeleteResponseResponse | null> {
  return Server.delete<Api.IDeleteResponseResponse>(`/response/${responseId}`, auth);
}

export function createDraftResponse(values: IManageResponseFormValues, panvivaDocumentId: string, panvivaDocumentVersion: number, auth: AuthState): Promise<Api.ICreateResponseResponse | null> {
  const completedValues: Api.ICreateResponseRequest = { ...mapValuesToSaveRequest(values, panvivaDocumentVersion), panvivaDocumentId };
  return Server.post<Api.ICreateResponseResponse>(`/draftresponse`, auth, completedValues);
}

export function editDraftResponse(values: IManageResponseFormValues, draftResponseId: string, panvivaDocumentVersion: number, auth: AuthState): Promise<Api.IUpdateResponseResponse> {
  const completedValues: Api.IUpdateResponseRequest = mapValuesToSaveRequest(values, panvivaDocumentVersion);
  return Server.put<Api.IUpdateResponseResponse>(`/draftresponse/${draftResponseId}`, auth, completedValues);
}

export function publishDraftResponse(values: IManageResponseFormValues, draftResponseId: string, panvivaDocumentVersion: number, auth: AuthState): Promise<Api.IPublishDraftResponseResponse> {
  const completedValues: Api.IPublishDraftResponseRequest = mapValuesToSaveRequest(values, panvivaDocumentVersion);
  return Server.post<Api.IPublishDraftResponseResponse>(`/draftresponse/${draftResponseId}/publish`, auth, completedValues);
}

export function saveAsDraftResponse(values: IManageResponseFormValues, responseId: string, panvivaDocumentVersion: number, auth: AuthState): Promise<Api.ISaveAsDraftResponseResponse> {
  const completedValues: Api.ISaveAsDraftResponseRequest = mapValuesToSaveRequest(values, panvivaDocumentVersion);
  return Server.post<Api.ISaveAsDraftResponseResponse>(`/response/draft/${responseId}`, auth, completedValues);
}

export function deleteDraftResponse(draftResponseId: string, auth: AuthState): Promise<Api.IDeleteDraftResponse | null> {
  return Server.delete<Api.IDeleteDraftResponse>(`/draftresponse/${draftResponseId}`, auth);
}

export function fetchContentCuration(artefactId: string, documentId: number, primaryQuery: string, documentContent: ISection[], auth: AuthState): Promise<IContentCurationResponse | null> {

  const documentContent1: DocumentContent[] = documentContent.map((x) => { return { sectionId: x.sectionId, sectionBody: x.richContent } });
  const completedValues: Api.IContentCurationRequest = { artefactId: artefactId, documentId: documentId, primaryQuery: primaryQuery, documentContent: documentContent1 };

  return Server.post<IContentCurationResponse>(`/assistant/generate/artefact`, auth, completedValues);
}

export function fetchResponseVariation(artefactId: string, documentId: number, channels: string[], responseContent: string, existingVariation: string, auth: AuthState): Promise<IResponseVariationResponse | null> {

  const request: Api.IResponseVariationRequest = { artefactId: artefactId, documentId: documentId, documentContent: responseContent, channel: channels, existingVariations: [existingVariation] };

  return Server.post<IResponseVariationResponse>(`/assistant/generate/responsevariations`, auth, request);
}

export function fetchSummary(artefactId: string, documentId: number, artefactContent: string, auth: AuthState): Promise<ISummaryResponse | null> {
  const SummaryRequest: Api.ISummaryRequest = { artefactId: artefactId, documentId: documentId, artefactContent: artefactContent };

  return Server.post<ISummaryResponse>(`/assistant/generate/summary`, auth, SummaryRequest);
}


export function mapValuesToSaveRequest(values: IManageResponseFormValues, panvivaDocumentVersion: number): Api.IIBaseResponseRequest {
  const completedValues: Api.IIBaseResponseRequest = {
    ...values,
    metadata: values.metadata || [],
    responseVariations: values.responseVariations ? values.responseVariations : [],
    panvivaDocumentVersion,
    taggedSections: values.taggedSections ? values.taggedSections.filter(x => x.checked).map((x: ISection) => {
      return {
        sectionId: x.sectionId,
        hash: x.hash
      }
    }) : [],
    queryVariations: values.queryVariations ? values.queryVariations.filter((variation: string) => variation && variation.length).map((x: string) => {
      return {
        id: 0,
        query: x
      }
    }) : []
  }

  return completedValues;
}
