import { AuthState } from "@okta/okta-auth-js";
import { Server } from "../../Shared/Util/Server";

export function fetchDrafts(pageNumber: number, itemsPerPage: number, sortOrder: string, auth: AuthState): Promise<Api.IDraftResponseListResponse|null> {
  let url = `/draftresponse?pageNumber=${pageNumber}&resultsPerPage=${itemsPerPage}`;
  if (sortOrder) {
    url = `${url}&sortOrder=${sortOrder}`;
  }
  return Server.get<Api.IDraftResponseListResponse>(url, auth);
}
