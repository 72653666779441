export function ShortenNumber(value: number, min?: number): string {
  min = min || 1e3;
  // Alter numbers larger than 1k
  if (value >= min) {
    const units = ["k", "M", "B", "T"];

    const order = Math.floor(Math.log(value) / Math.log(1000));

    const unitname = units[(order - 1)];
    const num = Math.floor(value / 1000 ** order);

    // output number remainder + unitname
    return num + unitname
  }

  // return formatted original number
  return value ? value.toLocaleString() : "0";
}
