import * as classNames from "classnames";
import * as React from "react";
import { WrappedFieldProps } from "redux-form";
import i18n from "../../../i18n";
import "./MarkupComponents.scss";
import "./StepPanel.scss";
import { Tooltip } from 'react-tooltip';

export const SectionSelectionPanel: React.FC<React.PropsWithChildren<{}>> = (props) => (
  <div className="select-document">{props.children}</div>
);

export const ColumnPanel: React.FC<React.PropsWithChildren<{}>> = (props) => (
  <div className="panel-flex">{props.children}</div>
);

export const MiddlePanel: React.FC<React.PropsWithChildren<{}>> = (props) => (
  <div className="panel-middle">{props.children}</div>
);

export const CopyButton: React.FC<{
  onClick: (evt: any) => any;
}> = (props) => (
  <button type="button" className="btn btn-sm" onClick={props.onClick}>
    <span className="double-chevron">
      <i className="fa fa-chevron-right" />
      <i className="fa fa-chevron-right" />
    </span>
    <span className="sr-only">
      {i18n.t("manage.documentSelection.copyButtonText")}
    </span>
  </button>
);

interface IStepPanelProps {
  stepNumber: string;
  heading: string;
  showStar?: boolean;
}

export const StepPanel: React.FC<React.PropsWithChildren<IStepPanelProps>> = (props) => (
  <div className="document-step-panel">
    <h4>
      <span className="step-number">{props.stepNumber}</span>
      {props.heading} {props.showStar && <sup className="star">*</sup>}{" "}
    </h4>
    {props.children}
  </div>
);

type ResponseBodyTextAreaProps = WrappedFieldProps & {
  disableSummariseResponse: boolean;
  enableAIAssistant: boolean;
  wandIconTooltipText?: string;
  fetchSummaryResponse: Function;
  undoResponses: string[];
  undoResponseAction: Function;
  onInputChange: Function;
  navigatedPageStateForResponse: boolean;
};

export const ResponseBodyTextArea = (field: ResponseBodyTextAreaProps) => (
  <div className="textarea-container">
    {
      field.enableAIAssistant ?
        <>
          <span className="disclaimer-text msg-tooltip-icon">
            <i className="icon icon-info-circle margin-right-2" />
            <span className="padding-top-1">{i18n.t("manage.tooltips.editResponseDisclaimer")}</span>
          </span>
          <div className="curation-block">
            <div className="dropdown">
              <div className="">
                {
                  field.undoResponses.length > 1 && !field.navigatedPageStateForResponse ?
                    <img className="EnabledUndoIcon" src="/images/undo.svg" alt="undo icon" onClick={() => field.undoResponseAction()} /> :
                    <img className="DisabledUndoIcon" src="/images/disabledUndo.svg" alt="undo icon" />
                }
                {
                  field.disableSummariseResponse ?
                    <>
                      <div className="wandicon" data-tooltip-id="response-editor-tooltip" data-tooltip-content={field.wandIconTooltipText}>
                        <span className={"msg-tooltip-icon"}>
                          <button className={classNames("padding-3 font-12", "disabled-button")}
                            type="button" id="dropdownMenuButton"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img className="greyWandicon" src="/images/greyWandicon.svg" alt="grey wand icon" />
                            AI KNOWLEDGE ASSISTANT
                            <img className="greyDownArrow" src="/images/greyDownArrow.svg" alt="grey down arrow icon" />
                          </button>
                        </span>
                      </div>
                      <Tooltip id="response-editor-tooltip" place={"right"} variant={"info"} className={"cc-tooltip"}
                        openOnClick={true} />
                    </>
                    :
                    <button className={classNames("btn padding-3 font-12 shadow-active", "btn-outline-secondary")}
                      type="button" id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      disabled={field.disableSummariseResponse}
                    >
                      <img className="enabled-wandicon" src="/images/wandicon.svg" alt="wand icon" />
                      AI KNOWLEDGE ASSISTANT
                      <img className="downArrow" src="/images/downArrow.svg" alt="down arrow icon" />
                    </button>
                }
                <div className="dropdown-menu btn padding-3 font-12 btn-outline-secondary" aria-labelledby="dropdownMenuButton">
                  <div onClick={() => field.fetchSummaryResponse()}>Summarise response</div>
                </div>
              </div>
            </div>
          </div> </> : null}
    <textarea
      className={classNames("form-control", { "is-invalid": field.meta.error })}
      {...field.input}
      onChange={(e) => {
        field.input.onChange(e.target.value);
        field.onInputChange(e.target.value); } }
    />
    {field.meta.error && (
      <span className="invalid-feedback">{field.meta.error}</span>
    )}
  </div>
);

export const SectionDeletedNotification: React.FC = () => (
  <div className="section-deleted-notification">
    <i className="fa fa-exclamation" />
    <p className="notification-text">
      {i18n.t("manage.documentSelection.sectionDeletedMessage")}
    </p>
  </div>
);

export const DocumentDeletedNotification: React.FC = () => (
  <div className="document-deleted-notification">
    <h5>{i18n.t("manage.documentSelection.documentDeletedHeading")}</h5>
    <p className="notification-message">
      {i18n.t("manage.documentSelection.documentDeletedMessage")}
    </p>
  </div>
);

export const UntaggableDocumentNotification: React.FC = () => (
  <div className="untaggable-document-notification">
    <h5>{i18n.t("manage.documentSelection.untaggableDocumentHeading")}</h5>
    <p className="notification-message">
      {i18n.t("manage.documentSelection.untaggableDocumentMessage")}
    </p>
  </div>
);

interface IADraftAlreadyExistsNotificationProps {
  hasDraft: boolean;
}

export const ADraftAlreadyExistsNotification: React.FC<IADraftAlreadyExistsNotificationProps> = ({
  hasDraft
}) =>
  hasDraft ? (
    <div className="manage-response-notifications">
      <div>
        <i className="icon icon-info-circle" />
        <p className="notification-text">
          {i18n.t("manage.aDraftAlreadyExistsNotificationMessage")}
        </p>
      </div>
      <div className="notification-button-container">
        <button
          className="btn btn-sm btn-primary notification-button"
          onClick={openDraftResponse}
        >
          {i18n.t("manage.openDraft")}
        </button>
      </div>
    </div>
  ) : null;


interface IDraftPublishedVersionProps {
  versionMismatch: boolean;
}

export const PublishedDraftVersionNotification: React.FC<IDraftPublishedVersionProps> = ({ versionMismatch }) =>
  versionMismatch
    ? (
      <div className="manage-response-notifications">
        <div>
          <i className="icon icon-info-circle" />
          <p className="notification-text">
            {i18n.t("manage.publishedVersionMismatchMessage")}
          </p>
        </div>
        <div className="notification-button-container">
          <button
            className="btn btn-sm btn-primary notification-button"
            onClick={openPublishedResponse}>
            {i18n.t("manage.openPublished")}
          </button>
        </div>
      </div>
    )
    : null;

function openResponse(isDraft: boolean) {
  const path = /(isDraft=)[^\&]+/.test(window.location.search)
    ? window.location.search.replace(/(isDraft=)[^\&]+/, `isDraft=${isDraft}`)
    : `${window.location.search}&isDraft=${isDraft}`;

  // While this is hacky it's the only way I've been able to get these buttons to work (since upgrading react router)
  // even when using the withRouter() hook which is supposed to allow appHistory and Link to work (This causes all tests to fail),
  // if we work it out we can uncomment the appHistory call or revert to using react-router-dom Link
  // appHistory.push(`${window.location.pathname}${path}`);
  window.location.replace(`${window.location.pathname}${path}`);

}

const openPublishedResponse = () => {
  openResponse(false);
};

const openDraftResponse = () => {
  openResponse(true);
};
