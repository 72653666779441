import { AuthState } from "@okta/okta-auth-js";
import { Server } from "../../Shared/Util/Server";

export function fetchResponses(pageNumber: number, itemsPerPage: number, sortOrder: string, auth: AuthState, searchKeyword?: string): Promise<Api.IResponseListResponse|null> {
  let url = `/response?pageNumber=${pageNumber}&resultsPerPage=${itemsPerPage}`;
  if (sortOrder) {
    url = `${url}&sortOrder=${sortOrder}`;
  }
  if(searchKeyword) {
    url = `${url}&searchKeyword=${searchKeyword}`
  }
  return Server.get<Api.IResponseListResponse>(url, auth);
}
