import { withOktaAuth } from "@okta/okta-react";
import { AuthState } from "@okta/okta-auth-js";
import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";
import { responseListDefaultSort, Routes } from "../../AppConstants";
import appHistory from "../../AppHistory";
import { validateAuthState } from "../../Auth/AuthHelper";
import i18n from "../../i18n";
import { IStoreState } from "../../Model";
import { Error } from "../../Shared/Error";
import { LoadingIndicator } from "../../Shared/LoadingIndicator";
import { Responses } from "../../Shared/ResponseTable/ResponseList";
import { Search } from "../../Shared/Search/Search";
import { getResponseListThunk } from "../duck/Actions";
import { WithOktaAuthProps } from "src/ts/Auth/duck/Models";

export interface IResponsesStateProps {
  failed: boolean;
  fetched: boolean;
  fetching: boolean;
  responses: Api.IPagedResult<Api.IResponseListViewModel>;
}

interface IResponsesPageDispatchProps {
  fetchResponseList: (
    pageNumber: number,
    itemPerPage: number,
    sortOrder: string,
    auth: AuthState | null,
    searchKeyword?: string
  ) => any;
}

interface IResponsesPageOwnProps {
  currentPage: number;
  sortOrder: string;
  searchKeyword: string;
}

type IResponsesProps = IResponsesStateProps &
  IResponsesPageDispatchProps &
  IResponsesPageOwnProps &
  WithOktaAuthProps;

class ResponseListPage extends React.Component<IResponsesProps> {
  constructor(props: IResponsesProps) {
    super(props);

    this.search = this.search.bind(this);
  }

  public componentDidMount() {
    if (this.props.authState?.isAuthenticated) {
      this.props.fetchResponseList(
        this.props.currentPage,
        this.props.responses.pageSize,
        `-${responseListDefaultSort}`,
        this.props.authState,
        this.props.searchKeyword || undefined
      );
    } else {
      validateAuthState(this.props.authState);
    }
  }

  public componentDidUpdate(prevProps: IResponsesProps) {
    if (this.props.authState.isAuthenticated) {
      if (
        prevProps.currentPage !== this.props.currentPage ||
        prevProps.sortOrder !== this.props.sortOrder ||
        prevProps.searchKeyword !== this.props.searchKeyword ||
        prevProps.responses.searchKeyword !==
          this.props.responses.searchKeyword ||
        prevProps.responses.sortOrder !== this.props.responses.sortOrder
      ) {
        this.props.fetchResponseList(
          this.props.currentPage,
          this.props.responses.pageSize,
          this.props.sortOrder || "",
          this.props.authState,
          this.props.searchKeyword || undefined
        );
      }
    } else {
      validateAuthState(this.props.authState);
    }
  }

  public render() {
    if (!this.props.authState) {
      return <LoadingIndicator busy={true} />
    }
    if (this.props.authState?.error) {
      throw this.props.authState.error;
    }
    return (
      <React.Fragment>
        <h1 className="title">{i18n.t("responseList.heading")}</h1>
        <Search
          applySearch={this.search}
          title={i18n.t("responseList.searchTitle")}
          tooltip={i18n.t("responseList.searchTooltip")}
          placeholder={i18n.t("responseList.searchPlaceHolder")}
          initialValue={this.props.searchKeyword}
        />

        {this.props.failed && (
          <Error message={i18n.t("responseList.fetchingResponsesFailed")} />
        )}
        <LoadingIndicator busy={this.props.fetching} />
        {this.props.fetched && (
          <Responses
            list={this.props.responses}
            baseRoute={Routes.responseList}
            isDraftList={false}
            handlePageSizeChange={this.props.fetchResponseList}
            authState={this.props.authState}
          />
        )}
      </React.Fragment>
    );
  }

  private search(searchKeyword: string) {
    let url = `${Routes.responseList}/1`;

    if (this.props.sortOrder) {
      url = `${url}?sortOrder=${this.props.sortOrder}&searchKeyword=${searchKeyword}`;
    } else {
      url = `${url}?searchKeyword=${searchKeyword}`;
    }

    appHistory.push(url);
  }
}

const mapStateToProps = (state: IStoreState) => {
  return {
    ...state.responses,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchResponseList: (
      pageNumber: number,
      itemPerPage: number,
      sortOrder: string,
      auth: AuthState,
      searchKeyword?: string
    ) => {
      dispatch(
        getResponseListThunk(
          pageNumber,
          itemPerPage,
          sortOrder,
          auth,
          searchKeyword
        )
      );
    },
  };
};

const hoc = compose(
  withOktaAuth,
  connect<
    IResponsesStateProps,
    IResponsesPageDispatchProps,
    IResponsesPageOwnProps
  >(mapStateToProps, mapDispatchToProps)
)(ResponseListPage);

export { hoc as ResponseListPage };
