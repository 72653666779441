import { reducer as reduxFormReducer } from 'redux-form';
import { manageResponseFormKey } from './AppConstants';
import { TenantReducer } from './Base/TenantSelector/duck/Reducer';
import { DraftsReducer } from './DraftResponses/duck/Reducer';
import { HomeReducer } from './Home/duck/Reducer';
import { ManageResponseFormReducer } from './ManageResponse/duck/FormReducer';
import { ManageResponseReducer } from './ManageResponse/duck/Reducer';
import { ResponsesReducer } from './Responses/duck/Reducer';
import { SearchResponseReducer } from './SearchResponses/duck/Reducer';
import { UpdateReducer } from './Updates/duck/Reducers';
import { SourcesReducer } from './Sources/duck/Reducer';
import { AnalyticsReducer } from './Analytics/duck/Reducer';
import { combineReducers } from 'redux';

const AppReducer = combineReducers({
  home: HomeReducer,
  form: reduxFormReducer.plugin({
    [manageResponseFormKey]: ManageResponseFormReducer
  }),
  manageResponse: ManageResponseReducer,
  searchResponse: SearchResponseReducer,
  updates: UpdateReducer,
  responses: ResponsesReducer,
  drafts: DraftsReducer,
  tenants: TenantReducer,
  sources: SourcesReducer,
  analytics: AnalyticsReducer
});

export default AppReducer;
