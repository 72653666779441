import { AuthState } from '@okta/okta-auth-js';
import { Dispatch } from 'redux';
import { Action } from 'redux';
import { fetchAnalytics, fetchAnalyticsCSV, fetchAnalyticsCSVExportStatus, triggerAnalyticsCSVExport } from './Operations';
import { Action as AnyAction } from '@reduxjs/toolkit';
import { IAnalyticsRequestParams } from '../container/AnalyticsPage';
import i18n from '../../../ts/i18n';

export enum AnalyticsActionTypes {
  FetchAnalyticsPending = "FETCH_ANALYTICS_PENDING",
  FetchAnalyticsSuccess = "FETCH_ANALYTICS_SUCCESS",
  FetchAnalyticsRejected = "FETCH_ANALYTICS_REJECTED",
  FetchAnalyticsCSVPending = "FETCH_ANALYTICS_CSV_PENDING",
  FetchAnalyticsCSVSuccess = "FETCH_ANALYTICS_CSV_SUCCESS",
  FetchAnalyticsCSVRejected = "FETCH_ANALYTICS_CSV_REJECTED",
  ClearCSVExportStatus = "CLEAR_CSV_EXPORT_STATUS"
}

export class FetchAnalyticsPendingAction implements Action {
  public readonly type = AnalyticsActionTypes.FetchAnalyticsPending;
}

export class FetchAnalyticsSuccessAction implements Action {
  public readonly type = AnalyticsActionTypes.FetchAnalyticsSuccess;
  constructor(public payload: { analytics: Api.IAnalyticsResponse }) { }
}

export class FetchAnalyticsFailedAction implements Action {
  public readonly type = AnalyticsActionTypes.FetchAnalyticsRejected;
  constructor(public payload: { error: any }) { }
}

export class FetchAnalyticsCSVPendingAction implements Action {
  public readonly type = AnalyticsActionTypes.FetchAnalyticsCSVPending;
  constructor(public payload: { inProgress: boolean, percentage: number }) { }
}

export class FetchAnalyticsCSVSuccessAction implements Action {
  public readonly type = AnalyticsActionTypes.FetchAnalyticsCSVSuccess;
  constructor(public payload: { csv: string }) { }
}

export class FetchAnalyticsCSVFailedAction implements Action {
  public readonly type = AnalyticsActionTypes.FetchAnalyticsCSVRejected;
  constructor(public payload: { error: any }) { }
}

export class ClearCSVExportStatusAction implements Action {
  public readonly type = AnalyticsActionTypes.ClearCSVExportStatus;
  constructor() { }
}


export type AnalyticsAction = FetchAnalyticsPendingAction
  | FetchAnalyticsSuccessAction
  | FetchAnalyticsFailedAction
  | FetchAnalyticsCSVPendingAction
  | FetchAnalyticsCSVSuccessAction
  | FetchAnalyticsCSVFailedAction
  | ClearCSVExportStatusAction;


export const getAnalyticsThunk: (auth: AuthState, requestParams: IAnalyticsRequestParams) => any = (auth, requestParams) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new FetchAnalyticsPendingAction());
    fetchAnalytics(auth, requestParams)
      .then((response: Api.IAnalyticsResponse) => {
        dispatch(new FetchAnalyticsSuccessAction({ analytics: response }));
      })
      .catch((errorResponse: any) => {
        dispatch(new FetchAnalyticsFailedAction({ error: errorResponse }));
      });
  }
}

export const triggerCSVExportThunk: (auth: AuthState, requestParams: IAnalyticsRequestParams, columnList: string) => any = (auth, requestParams, columnList) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new FetchAnalyticsCSVPendingAction({ inProgress: true, percentage: 0 }));
    let intervalId:any = null;
    triggerAnalyticsCSVExport(auth, requestParams, columnList)
      .then((response: any) => {
        intervalId = setInterval(() => fetchAnalyticsCSVExportStatus(auth, response.jobId)
        .then((response: any)=>{
          if(response.status === "COMPLETED"){
            clearInterval(intervalId);
            dispatch(getAnalyticsCSVThunk(auth, response.jobId));
          }else if(response.status === "STARTED" || response.status === "IN_PROGRESS" || response.status === "QUEUED"){
            dispatch(new FetchAnalyticsCSVPendingAction({ inProgress: true, percentage: response.progress }));
          }else{
            clearInterval(intervalId);
            dispatch(new FetchAnalyticsCSVFailedAction({ error: i18n.t("analytics.exportError") }));
          }
        })
        .catch((errorResponse: any) => {
          clearInterval(intervalId);
          dispatch(new FetchAnalyticsCSVFailedAction({ error: errorResponse }));
        }), 2000);
      })
      .catch((errorResponse: any) => {
        clearInterval(intervalId);
        dispatch(new FetchAnalyticsCSVFailedAction({ error: errorResponse }));
      });
  }
}

export const getAnalyticsCSVThunk: (auth: AuthState, jobId: string) => any = (auth, jobId) => {
  return (dispatch: Dispatch<AnyAction>) => {
    fetchAnalyticsCSV(auth, jobId)
      .then((response: string) => {
        dispatch(new FetchAnalyticsCSVSuccessAction({ csv: response }));
      })
      .catch((errorResponse: any) => {
        dispatch(new FetchAnalyticsCSVFailedAction({ error: errorResponse }));
      });
  }
}

export const clearCSVExportStatus: () => any = () => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new ClearCSVExportStatusAction());
  }
}