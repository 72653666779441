import { Action, Dispatch } from 'redux';
import { searchLinkedResponses, searchLinkedResponsesForExternalSources } from './Operations';
import { AuthState } from '@okta/okta-auth-js';
import { Action as AnyAction } from '@reduxjs/toolkit';

export enum SearchResponseActionTypes {
  SearchingResponses = 'SEARCH_RESPONSES_PENDING',
  SearchedResponses = 'SEARCHED_RESPONSES',
  SearchResponsesFailed = 'SEARCH_RESPONSES_FAILED',
  InitialiseResponseSearchState = 'INITIALISE_RESPONSESEARCH_STATE',
  SearchingResponsesForExternalSources = 'SEARCH_RESPONSES_PENDING_FOR_EXTERNAL_SOURCES',
  SearchedResponsesForExternalSources = 'SEARCHED_RESPONSES_FOR_EXTERNAL_SOURCES',
  SearchResponsesFailedForExternalSources = 'SEARCH_RESPONSES_FAILED_FOR_EXTERNAL_SOURCES',
  DefaultResponsesStateForExternalSources = 'DEFAULT_RESPONSE_STATE_FOR_EXTERNAL_SOURCES',
  DefaultResponsesStateForResponses = 'DEFAULT_RESPONSE_STATE_FOR_RESPONSES',
}

export class SearchingResponsesAction implements Action {
  public readonly type = SearchResponseActionTypes.SearchingResponses;
}

export class InitialiseResponseSearhAction implements Action {
  public readonly type = SearchResponseActionTypes.InitialiseResponseSearchState;
}

export class SearchedResponsesAction implements Action {
  public readonly type = SearchResponseActionTypes.SearchedResponses;
  constructor(public payload: {
    responses: Api.IPagedResult<Api.ICompositeResponseViewModel>,
    isDocumentIdValid: boolean,
    docId: number
  }) { }
}

export class SearchResponsesFailedAction implements Action {
  public readonly type = SearchResponseActionTypes.SearchResponsesFailed;
  constructor(public payload: { error: string }) { }
}

export class SearchedResponsesForExternalSourcesAction implements Action {
  public readonly type = SearchResponseActionTypes.SearchedResponsesForExternalSources;
  constructor(public payload: {
    externalSourcesResponses: Api.IExternalSourcesSearchResponse
  }) { }
}

export class SearchResponsesFailedForExternalSourcesAction implements Action {
  public readonly type = SearchResponseActionTypes.SearchResponsesFailedForExternalSources;
  constructor(public payload: { error: string }) { }
}

export class SearchingResponsesForExternalSourcesAction implements Action {
  public readonly type = SearchResponseActionTypes.SearchingResponsesForExternalSources;
}

export class DefaultResponsesStateForResponsesAction implements Action {
  public readonly type = SearchResponseActionTypes.DefaultResponsesStateForResponses;
}

export class DefaultResponsesStateForExternalSourcesAction implements Action {
  public readonly type = SearchResponseActionTypes.DefaultResponsesStateForExternalSources;
}

export type SearchResponsesAction = SearchingResponsesAction
  | SearchedResponsesAction
  | SearchResponsesFailedAction
  | InitialiseResponseSearhAction
  | SearchingResponsesForExternalSourcesAction
  | SearchedResponsesForExternalSourcesAction
  | SearchResponsesFailedForExternalSourcesAction
  | DefaultResponsesStateForResponsesAction
  | DefaultResponsesStateForExternalSourcesAction;

export const getLinkedResponsesThunk: (docId: number, pageNumber: number, itemPerPage: number, sortOrder: string, auth: AuthState) => any = (docId, pageNumber, itemPerPage, sortOrder, auth) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new SearchingResponsesAction());
    searchLinkedResponses(docId, pageNumber, itemPerPage, sortOrder, auth)
      .then((response: Api.IResponseSearchResponse) => {
        dispatch(new SearchedResponsesAction({ responses: response.compositeResponseList, docId, isDocumentIdValid: response.isDocumentIdValid }));
      })
      .catch((errorResponse: Error) => {
        // tslint:disable-next-line:no-console
        console.error(errorResponse);
        dispatch(new SearchResponsesFailedAction({ error: errorResponse.message }));
      });
  }
}

export const getLinkedResponsesForExternalSourcesThunk: (selectedTenant: string, query: string, pageNumber: number, itemPerPage: number, auth: AuthState) => any = (selectedTenant, query, pageNumber, itemPerPage, auth) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new SearchingResponsesForExternalSourcesAction());
    searchLinkedResponsesForExternalSources(selectedTenant, query, pageNumber, itemPerPage, auth)
      .then((response: Api.IExternalSourcesSearchResponse) => {
        //tslint-ignore
        dispatch(new SearchedResponsesForExternalSourcesAction({ externalSourcesResponses: { data: response.data, metadata: response.metadata, errors: [], hasErrors: false } }));
      })
      .catch((errorResponse: Error) => {
        // tslint:disable-next-line:no-console
        console.error(errorResponse);
        dispatch(new SearchResponsesFailedAction({ error: errorResponse.message }));
      });
  }
}
