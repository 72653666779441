import * as React from "react";
import { Collapse } from "./Collapse";

interface IAccordionProps {
  children?: Array<React.ReactElement<Collapse>> | React.ReactElement<Collapse>,
  id: string;
  className?: string
}

export class Accordion extends React.Component<IAccordionProps, {}> {
  public render() {
    return (
      <div className={`accordion ${this.props.className}`} id={this.props.id}>
        {this.props.children}
      </div>
    )
  }
}
