import * as React from 'react';
import { WrappedFieldArrayProps } from 'redux-form';
import { Accordion } from '../Collapse/Accordion';
import { Collapse } from '../Collapse/Collapse';
import './Tags.scss';

export interface ITagState {
  tag: string
}

export interface ITagProps extends WrappedFieldArrayProps<any> {
  label: string,
  name: string,
  uid: string,
  tooltipText: string,
  showWandIcon: boolean,
  getKeywords: Function,
  primaryResponse: string,
  disableWandIcon: boolean,
  wandIconTooltipText?: string
}

class Tags extends React.Component<ITagProps, ITagState> {
  constructor(props: ITagProps) {
    super(props);

    this.state = {
      tag: ''
    };

    this.addTag = this.addTag.bind(this);
    this.renderTags = this.renderTags.bind(this);
    this.handleTagChange = this.handleTagChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  public render() {
    return (
      <Accordion id={this.props.uid} className="tags-accordion">
        <Collapse id={`${this.props.uid}-collapse`} parentId={this.props.uid} title={this.props.label} tooltipText={this.props.tooltipText} showWandIcon={this.props.showWandIcon} fetchData={this.fetchData} disableWandIcon={this.props.disableWandIcon} wandIconTooltipText={this.props.wandIconTooltipText}>
          <div className="response-tags">
            <div className="input-group add-tags">
              <input type="text" className="form-control" value={this.state.tag} onKeyPress={this.handleKeyPress} onChange={this.handleTagChange}/>
              <div className="input-group-append">
                <button className="btn btn-primary" type="button" onClick={this.addTag}>Add</button>
              </div>
            </div>
            {this.props.fields.map(this.renderTags)}
          </div>
        </Collapse>
      </Accordion>
    );
  }

  private handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {

    if(e.key === 'Enter' && this.state.tag !== '') {
      e.preventDefault();
      this.addTag();
    }
  }

  private handleTagChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      tag: e.currentTarget.value
    });
  }

  private addTag() {
    let isDuplicate = false;
    for (let i = 0; i < this.props.fields.length; i++) {
      if (this.props.fields.get(i) == this.state.tag) {
        isDuplicate = true;
      }
    }
    if (isDuplicate == false && this.state.tag) {
      this.props.fields.push(this.state.tag);
    }
    this.setState({
      tag: ''
    });
  }

  public fetchData(numberto: number) {
    this.props.getKeywords(this.props.primaryResponse);
  }

  private removeTag = (index: number) => () => {
    this.props.fields.remove(index);
  }

  private renderTags(tag: string, index: number, fields: any) {
    return (
      <div className='tag' key={index}>
        {fields.get(index)}
        <i className='icon icon-close' onClick={this.removeTag(index)} />
      </div>
    )
  }
}

export {Tags}
