import { FunctionComponent, useState } from "react";
import * as classnames from 'classnames';
import "./SourceSearch.scss";
import i18n from "../../../../ts/i18n";
import { ISourceRequestParams } from "../../container/SourcesPage";

interface ISourceSearchProps {
    searchTextState: string;
    onSubmit: (text:string) => void;
    onBackClick: (requestParams: ISourceRequestParams) => void;
}
 
const SourceSearch: FunctionComponent<ISourceSearchProps> = (props) => {

    const { searchTextState, onSubmit, onBackClick } = props;

    const [searchText, setSearchText] = useState('');

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        if (!e.currentTarget.value) {
            setSearchText('');
        } else {
            setSearchText(e.currentTarget.value);
          } 
    }
    
    const handleKeyDown = (event: any) =>{
        if (event.key === ';' || event.key === 'Enter') {
            handleSubmit();
        }
    }

    const handleSubmit = () =>{
        if(searchText){
            onSubmit(searchText);            
        }
    }

    const handleBackClick = () =>{
        onBackClick({pageNumber: 1, pageSize: 10});
        setSearchText('');
    }

    return ( 
        <div className="source-search">
            
            {searchTextState && <div className="source-search-back">
                <button className="btn btn-light" aria-label="source-search-back-button" onClick={handleBackClick}>
                    <i className="fa fa-chevron-left" />
                </button>
            </div>}

            <div className="source-search-container">
                <input type="text" aria-label="search-source" className={classnames('form-control')}
                    value={searchText} onChange={handleInputChange} onKeyDown={handleKeyDown}
                    placeholder={i18n.t("sources.searchSourcePlaceholderText")}
                    />
                <button type="submit" aria-label="source-search-button" className="btn btn-primary" onClick={handleSubmit}>
                      {i18n.t("manage.search")}
                </button>
            </div>

        </div> );
}
 
export default SourceSearch;