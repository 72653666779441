import update from "immutability-helper";
import { HomeAction, HomeActionTypes } from "./Actions";
import { IHomeState } from "./Models";

const initialState: IHomeState = {
  failed: false,
  fetched: false,
  fetching: false,
  error: '',
  updateCount: 0,
  responseCount: 0,
  draftResponseCount: 0
};

export const HomeReducer = (
  state = initialState,
  action: HomeAction
): IHomeState => {
  switch (action.type) {
    case HomeActionTypes.FetchSummaryPending:
      return update(state, {
        fetched: { $set: false },
        fetching: { $set: true },
        failed: { $set: false },
        error: { $set: '' }
      });
    case HomeActionTypes.FetchSummaryRejected:
      return update(state, {
        updateCount: { $set: 0 },
        responseCount: { $set: 0 },
        draftResponseCount: { $set: 0 },
        error: { $set: action.payload.error },
        fetched: { $set: false },
        fetching: { $set: false },
        failed: { $set: true }
      });
    case HomeActionTypes.FetchedSummary:
      return update(state, {
        fetched: { $set: true },
        fetching: { $set: false },
        failed: { $set: false },
        error: { $set: '' },
        updateCount: { $set: action.payload.summary.updateCount },
        responseCount: { $set: action.payload.summary.responseCount },
        draftResponseCount: { $set: action.payload.summary.draftResponseCount },
      });
    default:
      return state;
  }
};
