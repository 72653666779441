import update from "immutability-helper";
import { DraftActionTypes, DraftsAction } from "./Actions";
import { IDraftsState } from "./Models";

const initialState: IDraftsState = {
  failed: false,
  fetched: false,
  fetching: false,
  error: '',
  drafts: {
    firstRowOnPage: 0,
    lastRowOnPage: 10,
    pageCount: 0,
    pageSize: 10,
    rowCount: 0,
    currentPage: 1,
    sortOrder: '',
    searchKeyword: '',
    results: []
  },
};

export const DraftsReducer = (
  state = initialState,
  action: DraftsAction
): IDraftsState => {
  switch (action.type) {
    case DraftActionTypes.FetchDraftsPending:
      return update(state, {
        fetched: { $set: false },
        fetching: { $set: true },
        failed: { $set: false },
        error: { $set: '' }
      });
    case DraftActionTypes.FetchDraftsRejected:
      return update(state, {
        drafts: { $set: { ...initialState.drafts } },
        error: { $set: action.payload.error },
        fetched: { $set: false },
        fetching: { $set: false },
        failed: { $set: true }
      });
    case DraftActionTypes.FetchedDrafts:
      return update(state, {
        fetched: { $set: true },
        fetching: { $set: false },
        failed: { $set: false },
        error: { $set: '' },
        drafts: { $set: { ...action.payload.drafts } }
      });
    default:
      return state;
  }
}
