import { nameof } from "../../Shared/Util/NameOf";
import { ManageAction, ManageActionTypes } from "../duck/Actions";
import { IManageResponseFormValues } from "../duck/Models";

export const ManageResponseFormReducer = (state: any, action: ManageAction): any => {
  const primaryResponseKey = nameof<IManageResponseFormValues>("primaryResponse");
  try {
    switch (action.type) {
      case ManageActionTypes.SetResponseBodyText:
        let newResponseBody = state.values[primaryResponseKey] ? state.values[primaryResponseKey].trim() : '';
        const selectedSections = action.payload.newSections ? action.payload.newSections : [];
  
        const uncopiedSections = selectedSections.filter(section => action.payload.oldSections.findIndex(foundSection => section.sectionId === foundSection.sectionId) < 0);
        newResponseBody += `${newResponseBody === '' || uncopiedSections.length === 0 ? '' : '\n\n'}` +
          `${uncopiedSections.map(section => section.plainContent ? section.plainContent.trim() : '').join('\n\n')}`;
  
        return {
          ...state,
          values: {
            ...state.values,
            [primaryResponseKey]: newResponseBody.replace(/\u00A0/g, ' ')
          }
        }
      default:
        return state;
    }
  } catch {
    // TODO: Clean-up this ugly hack to prevent Jest initializing issues.
    return state;
  }
}
