import { SecureRoute } from "@okta/okta-react";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { IStoreState } from "src/ts/Model";

interface IFeaturedRoutesProps {
    path: string;
    component?: React.ComponentType<any> | undefined;
    exact?: boolean | undefined;
    featureName: "analytics" | "externalSources";
}

enum FeatureMapping {
    analytics = "enableArtefactListingView",
    externalSources = "enableExternalDataSources"
  }
 
const FeaturedRoutes: FunctionComponent<IFeaturedRoutesProps> = ({path, component, exact, featureName}) => {

    const mounted = useRef<any>();
    const featureSettings = useSelector((state: IStoreState) => state.tenants.featureSettings);
    const [allowAccess, setAllowAccess] = useState<any>(true);

    useEffect(()=>{
        if (!mounted.current) {
            mounted.current = true;
          } else {
            setAllowAccess(featureSettings[FeatureMapping[featureName]])
          }
    },[featureSettings])


    return ( 
        allowAccess ? <SecureRoute
            path={path}
            component={component}
            exact={exact}/> : <Redirect to="/" />
     );
}
 
export default FeaturedRoutes;