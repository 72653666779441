import { AuthState } from '@okta/okta-auth-js';
import { Dispatch } from 'redux';
import { Action } from 'redux';
import { fetchSummary } from './Operations';
import { Action as AnyAction } from '@reduxjs/toolkit';

export enum HomeActionTypes {
  FetchSummaryPending = "FETCH_SUMMARY_PENDING",
  FetchedSummary = "FETCHED_SUMMARY",
  FetchSummaryRejected = "FETCH_SUMMARY_REJECTED",
}

export class FetchingSummaryAction implements Action {
  public readonly type = HomeActionTypes.FetchSummaryPending;
}

export class FetchedSummaryAction implements Action {
  public readonly type = HomeActionTypes.FetchedSummary;
  constructor(public payload: { summary: Api.ISummaryResponse }) {  }
}

export class FetchSummaryFailedAction implements Action {
  public readonly type = HomeActionTypes.FetchSummaryRejected;
  constructor(public payload: { error: string }) {  }
}

export type HomeAction = FetchingSummaryAction
  | FetchedSummaryAction
  | FetchSummaryFailedAction;


export const getSummaryThunk: (auth: AuthState) => any = (auth) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new FetchingSummaryAction());
    fetchSummary(auth)
      .then((response: Api.ISummaryResponse) => {
        dispatch(new FetchedSummaryAction({ summary: response }));
      })
      .catch((errorResponse: Error) => {
        // tslint:disable-next-line:no-console
        console.error(errorResponse);
        dispatch(new FetchSummaryFailedAction({ error: errorResponse.message }));
      });
  }
}
