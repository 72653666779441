import update from "immutability-helper";
import { AnalyticsAction, AnalyticsActionTypes } from "./Actions";
import { IAnalyticsState } from "./Models";
import i18n from "../../../ts/i18n";

const initialState: IAnalyticsState = {
  failed: false,
  fetched: false,
  fetching: false,
  error: '',
  data: [],
  total: 30,
  csvExport: 
  {
    fetchingCSV: { inProgress: false, percentage: 0},
    fetchedCSV: false,
    fetchFailedCSV: false,
    dataCSV: '',
    errorCSV: '',
  }
};

export const AnalyticsReducer = (
  state = initialState,
  action: AnalyticsAction
): IAnalyticsState => {
  switch (action.type) {
    case AnalyticsActionTypes.FetchAnalyticsPending:
      return update(state, {
        fetched: { $set: false },
        fetching: { $set: true },
        failed: { $set: false },
        error: { $set: '' }
      });
    case AnalyticsActionTypes.FetchAnalyticsRejected:
      return update(state, {
        data: { $set: [] },
        total: { $set: 0 },
        error: { $set: i18n.t("sources.serverError") },
        fetched: { $set: false },
        fetching: { $set: false },
        failed: { $set: true }
      });
    case AnalyticsActionTypes.FetchAnalyticsSuccess:
      return update(state, {
        fetched: { $set: true },
        fetching: { $set: false },
        failed: { $set: false },
        error: { $set: action.payload.analytics.results.length <= 0 ? 'No results found' : '' },
        data: { $set: action.payload.analytics.results },
        total: { $set: action.payload.analytics.total },
      });
    case AnalyticsActionTypes.FetchAnalyticsCSVPending:
      const pendingStateObj = {
        fetchedCSV: false,
        fetchingCSV: { inProgress: true, percentage: action.payload.percentage},
        fetchFailedCSV: false,
        errorCSV: ''
      }
      return update(state, {
        csvExport: { $set: pendingStateObj}
      });
    case AnalyticsActionTypes.FetchAnalyticsCSVRejected:
      const rejectedStateObj = {
        dataCSV: '',
        errorCSV: i18n.t("sources.serverError"),
        fetchedCSV: false,
        fetchingCSV: { inProgress: false, percentage: 0},
        fetchFailedCSV: true
      }
      return update(state, {
        csvExport: { $set: rejectedStateObj}
      });
    case AnalyticsActionTypes.FetchAnalyticsCSVSuccess:
      const url = window.URL.createObjectURL(
        new Blob([action.payload.csv])
    );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'dorc_report.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
      const successStateObj = {
        fetchedCSV: true,
        fetchingCSV: { inProgress: false, percentage: 100},
        fetchFailedCSV: false,
        errorCSV: '',
        dataCSV: action.payload.csv
      }
      return update(state, {
        csvExport: { $set : successStateObj}
      });
    case AnalyticsActionTypes.ClearCSVExportStatus:
      const clearStateObj = {
        fetchedCSV: false,
        fetchingCSV: { inProgress: false, percentage: 0},
        fetchFailedCSV: false,
        errorCSV: '',
        dataCSV: ''
      }
      return update(state, {
        csvExport: { $set: clearStateObj}
      });
    default:
      return state;
  }
};
