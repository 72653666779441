import { AuthState } from '@okta/okta-auth-js';
import * as classNames from 'classnames';
import * as React from "react";
import { Link } from "react-router-dom";
import appHistory from '../../AppHistory';
import i18n from "../../i18n";
import './Pagination.scss';
import { PaginationLink } from "./PaginationLink";

const BASE_SHIFT = 0;
const TITLE_SHIFT = 1;
const BLOCK_SIZE = 4;

function range(start: number, end: number) {
  const res = [];
  for (let i = start; i < end; i++) {
    res.push(i);
  }

  return res;
}

const pageSizeCollection = [
  5, 10, 20, 30, 40, 50, 100
];

class Pagination extends React.Component<IPageLinkProps> {

  constructor(props: IPageLinkProps) {
    super(props);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
  }

  public render() {
    const prevClass = classNames('page-item', {'disabled': this.isPrevDisabled()});
    const nextClass = classNames('page-item', {'disabled': this.isNextDisabled()});
    const previousPage = this.props.currentPage > 1 ? this.props.currentPage - TITLE_SHIFT : 1;
    const nextPage = this.props.currentPage >= this.props.pageCount ? this.props.pageCount : this.props.currentPage + TITLE_SHIFT;

    const firstPageRoute = {
      ...appHistory.location,
      pathname: `${this.props.linkUrlLeftPart}/1`
    };
    const lastPageRoute = {
      ...appHistory.location,
      pathname: `${this.props.linkUrlLeftPart}/${this.props.pageCount}`
    }
    const nextPageRoute = {
      ...appHistory.location,
      pathname: `${this.props.linkUrlLeftPart}/${nextPage}`
    };
    const prevPageRoute = {
      ...appHistory.location,
      pathname: `${this.props.linkUrlLeftPart}/${previousPage}`
    };

    const nextBlockRoute = {
      ...appHistory.location,
      pathname: `${this.props.linkUrlLeftPart}/${this.props.currentPage + (BLOCK_SIZE - this.props.currentPage) + TITLE_SHIFT}`
    };

    const prevBlockRoute = {
      ...appHistory.location,
      pathname: `${this.props.linkUrlLeftPart}/${this.props.currentPage - (this.props.currentPage - BLOCK_SIZE)}`
    };

    return (
      <nav className="pagination-container">
        <ul className="pagination">
          <li className={prevClass}>
            <Link className="page-link" to={firstPageRoute}>
              <i className="icon angle-right-back fa-lg" aria-hidden="true" />
            </Link>
          </li>

          <li className={prevClass}>
            <Link className="page-link" to={prevPageRoute}>
              <i className="icon angle-left fa-lg" aria-hidden="true" />
            </Link>
          </li>

          {!this.isPrevMoreHidden() &&
            <li className="page-item">
              <Link className="page-link" to={prevBlockRoute}>
                ...
              </Link>
            </li>
          }

          {this.renderPages(this.visibleRange())}

          {!this.isNextMoreHidden() &&
            <li className="page-item">
              <Link className="page-link" to={nextBlockRoute}>
                ...
              </Link>
            </li>
          }

          <li className={nextClass}>
            <Link className="page-link" to={nextPageRoute}>
              <i className="icon angle-right fa-lg" aria-hidden="true" />
            </Link>
          </li>

          <li className={nextClass}>
            <Link className="page-link" to={lastPageRoute}>
              <i className="icon angle-right-next fa-lg" aria-hidden="true" />
            </Link>
          </li>
        </ul>
        <div className="page-size">
          <select className="form-control" onChange={this.handlePageSizeChange} value={ this.props.pageSize }>
            {pageSizeCollection.map((x, ind) => <option key={ind} value={x}>{x}</option>)}
          </select>
          <div className="item-per-page">{i18n.t("pagination.itemPerPage")}</div>
        </div>

      </nav>
    )
  }

  private renderPages(pair: any) {
    return range(pair[0], pair[1]).map((num, idx) => {
      return (
        <PaginationLink
          key={idx}
          index={idx}
          currentPage={this.props.currentPage}
          page={num}
          linkUrlLeftPart={this.props.linkUrlLeftPart}
        />
      );
    });
  }

  private handlePageSizeChange(event: React.FormEvent<HTMLSelectElement>) {
    event.preventDefault();
    this.props.onPageSizeChange(1, +event.currentTarget.value, this.props.authState);
  }

  private calcBlocks() {
    const total = this.props.pageCount;
    const blockSize = this.props.pageCount < BLOCK_SIZE ? this.props.pageCount : BLOCK_SIZE;
    const current = this.props.currentPage;
    const blocks = Math.ceil(total / blockSize);
    const currBlock = Math.ceil(current / blockSize) - TITLE_SHIFT;

    return {
      total: blocks,
      current: currBlock,
      size: blockSize,
    };
  }

  private isPrevDisabled() {
    return this.props.currentPage <= TITLE_SHIFT;
  }

  private isNextDisabled() {
    return this.props.currentPage >= this.props.pageCount;
  }

  private isPrevMoreHidden() {
    const blocks = this.calcBlocks();
    return (blocks.total === TITLE_SHIFT) || (blocks.total === this.props.pageCount) || (blocks.current === BASE_SHIFT);
  }

  private isNextMoreHidden() {
    const blocks = this.calcBlocks();
    return (blocks.total === TITLE_SHIFT) || (blocks.current === (blocks.total - TITLE_SHIFT));
  }

  private visibleRange() {
    const blocks = this.calcBlocks();
    const start = blocks.current * blocks.size;
    const delta = this.props.pageCount - start;
    const end = start + ((delta > blocks.size) ? blocks.size : delta);

    if ((start + TITLE_SHIFT) === end) {
      return [end - blocks.size + TITLE_SHIFT, end + TITLE_SHIFT]
    }

    return [start + TITLE_SHIFT, end + TITLE_SHIFT];
  }
}

interface IPageLinkProps {
  currentPage: number;
  totalRecordsNumber: number;
  pageCount: number;
  linkUrlLeftPart: string;
  titles?: any,
  pageSize: number;
  sortOrder?: string;
  onPageSizeChange: (pageNumber: number, itemPerPage: number, auth: AuthState | null) => any;
  authState: AuthState | null;
}


export default Pagination;
