import { FunctionComponent } from "react";
import { AnalyticsPage } from "./container/AnalyticsPage";
import { WithOktaAuthProps } from 'src/ts/Auth/duck/Models';
import { withOktaAuth } from "@okta/okta-react";


const Analytics: FunctionComponent<React.PropsWithChildren<WithOktaAuthProps>> = (props: WithOktaAuthProps) => {
  return <AnalyticsPage {...props} />
}

export default withOktaAuth(Analytics);
