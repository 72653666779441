import * as React from "react";
import appHistory from '../../../AppHistory';
import i18n from '../../../i18n';
import { CardPage } from '../../../Shared/CardPage/CardPage';
import './ResponseSaved.scss';

export interface IResponseSavedProps {
  title: string;
  category: string;
}
export const ResponseSaved: React.FC<IResponseSavedProps> = (props: IResponseSavedProps) => (
  <CardPage pageTitle={i18n.t('manage.responseSavedTitle')} className="response-saved">
    <div>
      <i className='icon icon-checkmark-circle' />
    </div>
    <div className='details'>
      <p className='key'>{i18n.t('manage.response.title')}:</p>
      <p className='value'>{props.title}</p>
      <p className='key'>{i18n.t('manage.response.category')}:</p>
      <p className='value'>{props.category}</p>
    </div>
    <div className='ok-btn'>
      <button className='btn btn-lg btn-primary' onClick={handleOkClick}>{i18n.t('manage.ok')}</button>
    </div>
  </CardPage>
)

const handleOkClick = () => {
  appHistory.push('/home/responses/1');
}
