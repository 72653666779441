import { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "src/ts/Model";
import { Card, Popover } from '@panviva/panviva-react-ui';
import './ColumnSelector.scss';

interface ColumnSelectorProps {
    columnList:any[];
    onColumnSelection: (event:any, index:number, list:any[], selectAll?: boolean) => void;
    onExport: () => void
}
 
const ColumnSelector: FunctionComponent<ColumnSelectorProps> = (props) => {

    const analyticsDetails = useSelector((state: IStoreState) => state.analytics);

    const { columnList, onColumnSelection, onExport } = props;

    const [openSelection, setOpenSelection] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [columnListItems, setColumnListItems] = useState<any[]>();

    const id = openSelection ? 'popper-async-dropdown' : undefined;

    useEffect(()=>{
        setColumnListItems(columnList);
    },[columnList])

    useEffect(()=>{
        setOpenSelection(Boolean(anchorEl));
    },[anchorEl])

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelection = (event:any, index:number) =>{
        const updatedList = JSON.parse(JSON.stringify(columnListItems));
        updatedList[index].checked = event.target.checked;
        onColumnSelection(event, index, updatedList);
        setColumnListItems(updatedList);
    }


    const selectAllColumns = (event:any) =>{
        const updatedList = JSON.parse(JSON.stringify(columnListItems));
        // skip mandatrory disabled columns when show/hide all.
        updatedList.map((item:any)=>{ if(item.disabled !== true) { item.checked = event.target.checked }});
        onColumnSelection(event, 0, updatedList, true);
        setColumnListItems(updatedList);
    }

    const getSelectedColumnLength = () =>{
        return columnListItems?.filter((column:any)=>column.checked).length;
    }

    return ( 
    <div className="table_options">

        <div className='column_menu'>
            
            <span className="menu_item" onClick={handleClick}>
                <span className='icon icon-menu icon-rotate'></span>
                {' COLUMNS'}
            </span>

            <span className={`menu_item${analyticsDetails.csvExport?.fetchingCSV.inProgress ? ' disabled' : ''}`} onClick={onExport}>
                <span className='icon icon-download'></span>
                {' EXPORT'}
            </span>

        </div>

        <Popover id={id} open={openSelection} anchorEl={anchorEl} onClose={() => handleClose()} anchorOrigin={{vertical:'bottom'}}>
                <Card className='selection_popup'>
                    {columnListItems?.map((column:any, index:number) =>{
                        return <div key={column.name} className="selection_checkbox">
                                    <input type="checkbox" id={column.name} disabled={column.disabled} value={column.checked} checked={column.checked} onChange={(event)=>handleSelection(event, index)}/>&nbsp;
                                    <label>{column.name}</label><br></br>
                                </div>
                    })}
                    <div className="selection_checkbox select_all">
                        <input type="checkbox" id='show-all' checked={getSelectedColumnLength() === columnListItems?.length} onChange={(event)=>selectAllColumns(event)}/>&nbsp;
                        <label>{'Hide/Show All'}</label><br></br>
                    </div>
                </Card>
        </Popover>
    
    </div>
    );
}
 
export default ColumnSelector;