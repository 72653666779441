import { IManageResponseFormValues } from "../../ManageResponse/duck/Models";
import { nameof } from "./NameOf";

const fieldsInOrder = [
  nameof<IManageResponseFormValues>("title"),
  nameof<IManageResponseFormValues>("taggedSections"),
  nameof<IManageResponseFormValues>("primaryResponse"),
  nameof<IManageResponseFormValues>("category"),
  nameof<IManageResponseFormValues>("primaryQuery"),
  nameof<IManageResponseFormValues>("responseVariations"),
];

export const HandleSubmitFailure = (errors: any) => {
  fieldsInOrder.find((field: string) => {
    if(field !== nameof<IManageResponseFormValues>("responseVariations")) {
      if(errors[field]) {
        const element = document.querySelector(`[name="${field}"]`) || document.querySelector(`#${field}`);
        if(element) {
          element.scrollIntoView({
            behavior: 'smooth'
          });
          return true;
        }
      }
    } else {
      Object.keys(errors[field]).find((variation: string) => {
        const element = document.getElementById(`responseVariations[${variation}]`);
        if(element) {
          element.scrollIntoView({
            behavior: 'smooth'
          });
          return true;
        }
        return false;
      });
    }

    return false;
  })
}
