import { AuthState } from "@okta/okta-auth-js";
import { Server } from "../../../Shared/Util/Server";

export function fetchUsersTenants(auth: AuthState): Promise<Api.IGetUserTenantResponse|null> {
  return Server.get<Api.IGetUserTenantResponse>(`/tenant/list`, auth);
}

export function updateDefaultTenant(tenant: string, auth : AuthState): Promise<Api.IUpdateTenantResponse|null> {
  return Server.post<Api.IUpdateTenantResponse>(`/tenant/update`, auth, { tenantName: tenant });
}

export function getTenantFeatureSettings(tenant: string, auth : AuthState): Promise<Api.ITenantFeatureSettingsResponse|null> {
  return Server.get<Api.ITenantFeatureSettingsResponse>(`/tenant/settings/${tenant}`, auth);
}