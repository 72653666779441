import './EmptyListModal.scss';

export interface IEmptyModalProps {
  title: string;
  message: string;
}

export const EmptyListModal = (props: IEmptyModalProps) => (
  <div className="up-to-date-modal">
    <img className="empty-list-icon" src="/images/NotePad.svg" alt="Empty update list"/>
    <h5 className="up-to-date-heading">{props.title}</h5>
    <p>{props.message}</p>
  </div>
);
