
import { AuthState } from '@okta/okta-auth-js';
import { Server } from '../../Shared/Util/Server';
import { ISourceRequestParams } from '../container/SourcesPage';

export function fetchSources(auth: AuthState, requestParams: ISourceRequestParams, tenantName: string): Promise<Api.ISourcesResponse|null> {
  return Server.get<Api.ISourcesResponse>(`/documents?pageSize=${requestParams.pageSize}&pageNumber=${requestParams.pageNumber}`, auth);
}

export function fetchSearchSources(auth: AuthState, searchText:string, requestParams: ISourceRequestParams, tenantName: string): Promise<Api.ISourcesResponse|null> {
  return Server.get<Api.ISourcesResponse>(`/documents/search?query=${searchText}&pageSize=${requestParams.pageSize}&pageNumber=${requestParams.pageNumber}`, auth);
}
