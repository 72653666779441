import { SubmissionError } from "redux-form";
import i18n from "../../i18n";
import { arrayRequiredRule, requiredRule } from "../../Shared/Forms";
import { nameof } from "../../Shared/Util/NameOf";
import { IManageResponseFormValues } from "../duck/Models";

export const validateManageResponseForm = (formValues: IManageResponseFormValues, isDraft: boolean = false) => {
  let requiredFields = [
    nameof<IManageResponseFormValues>("title"),
  ];

  if (!isDraft) {
    requiredFields = [...requiredFields,
    nameof<IManageResponseFormValues>("category"),
    nameof<IManageResponseFormValues>("primaryQuery"),
    nameof<IManageResponseFormValues>("primaryResponse"),
    ];
  }

  const fieldErrors: any[] = [];

  // Required text fields
  requiredFields.forEach((fieldName) => {
    const error = requiredRule(formValues[fieldName])
    if (error) {
      fieldErrors.push({ [fieldName]: error });
    }
  });

  // Required fields with array value

  // Response variations
  if(formValues.responseVariations) {
    const rvError: any = { };

    formValues.responseVariations.forEach((field, index) => {
      const contentError = requiredRule(field.content);
      const channelError = arrayRequiredRule(field.channels as []);

      if(contentError || channelError) {
        rvError[index] = {};

        if(contentError) {
          rvError[index].content = contentError;
        }

        if(channelError) {
          rvError[index].channels = {_error: i18n.t("manage.responseVariations.atLeastOneChannelMsg")};
        }
      }
    });
    if(Object.keys(rvError) && Object.keys(rvError).length) {
      fieldErrors.push({responseVariations: rvError});
    }
  }

  if (fieldErrors.length) {
    throw new SubmissionError(fieldErrors.reduce((errorObj, currentError) => ({ ...errorObj, ...currentError })));
  }
}
