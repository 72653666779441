import { NavLink } from 'react-router-dom';
import './Tile.scss';

interface ITileProps {
  heading: string;
  count: string;
  subtitle: string;
  route: string;
}

export const Tile = (props: ITileProps) => (
  <NavLink to={props.route} activeClassName="active">
    <div className="home-tile">
      <div className="heading">{props.heading}</div>
      <span className="count">{props.count}</span>
      <span className="subtitle">{props.subtitle}</span>
    </div>
  </NavLink>

);
