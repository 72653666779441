import * as classNames from 'classnames';
import { IPanvivaFieldProps } from "../Forms";
import { DORCTooltip } from '../Tooltip';

export const FormInput = (props: IPanvivaFieldProps) => (
  <div className={
      classNames(
        "form-group",
        { "required": props.required }
      )
    }>
      <div className="field-label">
        <h5>{props.label}</h5>
        {props.tooltipText &&
          <DORCTooltip bootstrapIcon="icon-info-circle" tooltipText={props.tooltipText} placement="right" type="info"
                           iconClass="msg-tooltip-icon" triggerEvent="click" tooltipClass="cc-tooltip"/>
        }
      </div>
      <div className={classNames('input-container', {'input-container-with-badge': props.badgeText})}>
        { props.badgeText && <span className="badge badge-secondary">{props.badgeText}</span> }
        <input {...props.input} type={props.type} className={classNames('form-control', { 'is-invalid': props.meta.error })} placeholder={props.placeholder}/>
        <div className="invalid-feedback">
          { props.meta.error }
      </div>
      </div>
  </div>
);
