import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { InvalidIntegerRedirector } from '../Shared/Util/InvalidIntegerRedirector';
import { UpdatePage } from './container/UpdatePage';
import './Update.scss';

interface IUpdateProps extends RouteComponentProps<any>  {
  pageNumber: string;
}

export const Update : React.FC<IUpdateProps> = (props: IUpdateProps) =>
(
    <InvalidIntegerRedirector numberStrings={[props.match.params.pageNumber]}>
        <UpdatePage {...{currentPage: parseInt(props.match.params.pageNumber, 10)}} />
    </InvalidIntegerRedirector>
);
