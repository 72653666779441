import * as React from 'react';
import { Provider } from 'react-redux';
import { AppRouter } from './AppRouter';
import { store } from './AppStore';
// COMPONENT

class App extends React.Component<Api.ISpaConfigurationResponse> {
  constructor(props: Api.ISpaConfigurationResponse) {
    super(props);
  }
  public render() {
    return (
      <Provider store={store}>
        <AppRouter {...this.props}/>
      </Provider>
    )
  }
}

export default App;